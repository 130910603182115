import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, SharedService, SeoServiceService} from '../service/index';
import { OwlOptions } from 'ngx-owl-carousel-o';


import { contactForm } from '../forms'
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})
export class CustomerServiceComponent implements OnInit {
 tUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
 cutomeoption : OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    smartSpeed: 450,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  contactForm =contactForm;
  message;
  loadMore;
  location = [];
    constructor(private fb: FormBuilder,  private api: ApiService, private shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService: SeoServiceService,) { 
      this.activatedRoute.fragment.subscribe( fragment =>{
    // this.shared.cleardata();
    this.message = undefined;
    this.loadMore = false;
    // this.getCategory();
    this.contactForm.reset();
  })
  // this.shared.cleardata();
  this.loadMore = false;
  // this.getCategory();
  this.getLocation()
  this._seoService.updateTitle('Delivering Exceptional Customer Service at the Earliest | Coalesce Quartz');
  this._seoService.updateDescription('For any guidance about quartz products, meet our experts in-person or reach us via email or call. We are there to help you select the best for your space.')
}

  submitted: boolean= false;
  ngOnInit(): void {
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      location: ['', Validators.required],
    });
  }
  ngAfterViewInit(){
    // $(document).ready(function() {
    //   $('.slider4').owlCarousel();
    
    
    // })
  }
  get f() { return this.contactForm.controls; }

  onContactus(d) {
    this.submitted = true;
    this.loadMore = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.loadMore = false;
      return;
     }
     this.message = '';
     let formData: FormData = new FormData(); 
     formData.append('location_name', d.location,); 
     formData.append('Name', d.first_name); 
     formData.append('Email', d.email); 
     formData.append('Message', d.message); 
     formData.append('Subject', d.subject); 
     formData.append('website', 'coalesce-quartz' ); 

    this.api.fetchData('contactpage',formData,"POST" ).subscribe(res=> {
      if(res.status == 1) {
        this.message  = res.msg;
        this.contactForm.reset();
        this.submitted = false;
        this.loadMore = false;
        setTimeout(()=>{
          this.message = '';
         }, 3000)
      } else {
        this.message  = res.msg;
        this.submitted = false;
        this.loadMore = false;
      }
    })   
  }

  selectlocation(value){
    localStorage.setItem('location',value);
  }

  // getCategory() {
  //   this.api.fetchData('product_category',{},'GET').subscribe(
  //     res => {
  //       if(res['status'] == 1) {
  //         this.shared.categoryList = res['category']
  //       }
  //     }
  //   )
  // }
  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
        } else {
          this.location =  [];

        }
      }
    )
  }



}
