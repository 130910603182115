import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import {
  AbstractControl,
  CheckboxControlValueAccessor,
  FormArray,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';

import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import {ApiService} from '../service/index'

@Component({
  selector: 'app-warrantyregistration',
  templateUrl: './warrantyregistration.component.html',
  styleUrls: ['./warrantyregistration.component.css']
})
export class WarrantyregistrationComponent implements OnInit {
  waarentyForm:any;
  items:any;
  todayDate : Date = new Date();
  submitted = false;
  isBrowser : boolean;
  siteKey = environment.siteKey
  message = '';
  loadMore = false;
  location:any = [];
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';

  constructor(private fb: FormBuilder,@Inject(PLATFORM_ID) private platformId, private api: ApiService) { 
    this.isBrowser = isPlatformBrowser(platformId) ?isPlatformBrowser(platformId): true;

  }

  ngOnInit(): void {
    
    this.waarentyForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      zipcode: ['', Validators.required],
      country: ['', Validators.required],
      aptno: [''],
      subdivision: [''],
      // invoice_number:['',[Validators.required]],
      location: ['', Validators.required],
      installation_date: ['', Validators.required],
      total_square_footage: ['', Validators.required],
      application: [''],
      installation_type: ['', Validators.required],
      company_name: ['', Validators.required],
      f_city: [''],
      f_street: [''],
      f_zipcode: [''],
      f_state: [''],
      f_country: [''],
      captcha: ['', Validators.required],
      product: new FormArray([ ]),
    });
    this.waarentyForm.get('installation_date').patchValue(this.formatDate(new Date()));
    this.addItem({})
    this.getLocation()

  }


  get f() { return this.waarentyForm.controls; }
	addItem(data:any={}): void {
		this.items = this.waarentyForm.get('product') as FormArray;
		this.items.push(this.createItem(data));
	  }
	remove(i:any): void {
	this.items = this.waarentyForm.get('product') as FormArray;
	this.items.removeAt(i);
	}
	createItem(data:any): FormGroup {
		return this.fb.group({
		  color: [''],
		  serial_number: [' ', Validators.required ],


		});
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  // getPhonesFormControls(): AbstractControl[] {
  //   return (<FormArray> this.waarentyForm.get('product')).controls
  // }

  private formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  submit(data:any){
    console.log(data)
    this.submitted = true;
    if (this.waarentyForm.invalid) {
      return;
  }
  
    console.log(data);
    data['serial_number1'] = []
    data['color1'] = []
    data.product.map((f:any)=> {
      data['serial_number1'].push(f.serial_number)
      data['color1'].push(f.color)
    })
    data['color'] =data['color1']
    data['serial_number'] =data['serial_number1']
    console.log(data)
    let formData: FormData = new FormData(); 
    formData.append('firstname', data.firstname); 
    formData.append('lastname', data.lastname); 
    formData.append('email', data.email); 
    formData.append('phoneNumber', data.phoneNumber); 
    formData.append('street', data.street); 
    formData.append('city', data.city); 
    formData.append('zipcode', data.zipcode); 
    formData.append('country', data.country); 
    formData.append('aptno', data.aptno); 
    formData.append('subdivision', data.subdivision); 
    formData.append('location', data.location); 
    formData.append('installation_date', data.installation_date); 
    formData.append('total_square_footage', data.total_square_footage); 
    formData.append('application', data.application); 
    formData.append('installation_type', data.installation_type); 
    formData.append('company_name', data.company_name); 
    formData.append('f_city', data.f_city); 
    formData.append('f_street', data.f_street); 
    formData.append('f_zipcode', data.f_zipcode); 
    formData.append('f_state', data.f_state); 
    formData.append('f_country', data.f_country); 
    formData.append('serial_number', data.serial_number); 
    formData.append('color', data.color); 
  
  
 
  
    this.loadMore = true;
    this.api.fetchData('warranty-registration',formData, 'POST' ).subscribe(res=> {
      this.loadMore = false;

      if(res.status == 1) {
        this.waarentyForm.reset();
        this.submitted = false;
       this.message = res.msg
       this.waarentyForm.patchValue({
         captcha:'',
         installation_date:this.formatDate(new Date())
       })

       this.captchaElem.resetCaptcha();
       this.removeformarray()
       setTimeout(()=>{
        this.message = '';
       }, 10000)
      } else {
        this.message = res.msg
        this.submitted = false;
        setTimeout(()=>{
          this.message = '';
         }, 10000)
  
      }
    })
  }

  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
        } else {
          this.location =  [];
        }
      }
    )
  }
  handleSuccess(e) {
  }

  removeformarray(): void {
    this.items = this.waarentyForm.get('product') as FormArray;
    // console.log(this.items.length)
    let length = this.items.length;
    for( let i=1; i < length; i++){
      this.items.removeAt(i);
    }
    }
}


