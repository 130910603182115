import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { contactForm } from '../forms';
import { ApiService, SharedService, SeoServiceService} from '../service/index';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  cutomeoption : OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    smartSpeed: 450,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  contactForm =contactForm;
  message
  contactFormres
  loadMore
  location = []
    public map: any = { lat: 39.7797003, lng: -86.272835 };
  getLatLng = [
      {lat: 33.771191, lng: -84.598640, name: 'Atlanta GA' , location: 'Atlanta GA, 123 Interstate W. Pkwy.,Lithia Springs, GA'},
      { lat: 29.839710, lng: -95.511550, name: 'Houston TX' , location: 'Houston TX 8017 Pinemont Dr. #300, Houston, TX'},
      {lat: 40.809870, lng: -74.016660, name: 'North Bergen NJ' , location: 'North Bergen NJ 2038 83rd Street, North Bergen, NJ'},
      { lat: 40.439610, lng: -75.375960, name: 'Quakertown PA' , location: 'Quakertown PA 2700 Milford Square Pike, Quakertown, PA'},
      {lat: 41.890120, lng: -72.673760, name: 'Windsor CT' , location: 'Windsor CT 800 Marshall Phelps Rd. #2, Windsor, CT'},
      ]
  constructor(private fb: FormBuilder, private api: ApiService, private shared: SharedService,  private _seoService: SeoServiceService,) {
    // this.shared.cleardata();
    this.loadMore = false;

    // this.getCategory();
    this.getLocation()
    this._seoService.updateTitle('Contact Us | Coalesce Quartz');
    this._seoService.updateDescription('We have a team of quartz experts to assist you in selecting the best product for your space or setting. Reach us through email or call us for any concerns.')
   
   }
  submitted: boolean= false;
  ngOnInit(): void {
    this.contactFormres = undefined;
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      location: ['', Validators.required],
    });
    this.message = ''
  }

  ngAfterViewInit(){


  }
  get f() { return this.contactForm.controls; }

  onContactus(d) {
    this.loadMore = true;
    this.submitted = true;
    this.message = '';
    this.contactForm     // stop here if form is invalid
     if (this.contactForm.invalid) {
      this.loadMore = false;
      return;
     }
  
    let formData: FormData = new FormData(); 
    formData.append('location_name', d.location,); 
    formData.append('Name', d.first_name); 
    formData.append('Email', d.email); 
    formData.append('Message', d.message); 
    formData.append('Subject', d.subject); 
    formData.append('website', 'coalesce-quartz' ); 
 
  this.api.fetchData('contactpage',formData,"POST" ).subscribe(res=> {
    this.contactFormres  = res
    if(res.status == 1) {
      this.contactForm.reset();
      this.submitted = false;
      this.loadMore = false;
     this.message = res.msg
     setTimeout(()=>{
      this.message = '';
     }, 3000)
    } else {
      this.contactFormres  = res
      this.message = res.msg
      this.submitted = false;
      this.loadMore = false;
    }
  })     
  }






  // getCategory() {
  //   this.api.fetchData('product_category',{},'GET').subscribe(
  //     res => {
  //       if(res['status'] == 1) {
  //         this.shared.categoryList = res['category']
  //       }
  //     }
  //   )
  // }

  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
        } else {
          this.location =  [];

        }
      }
    )
  }
}


