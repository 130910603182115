<main>
   <section class="tringle AraAbt secPad2 bg-light">
      <div class="container py-md-5 py-3">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="capAbt">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <h2 class="mb-3">CONTACT US</h2>
                  </div>
                  <p class="text-left mb-1">
                     Whether you have questions about pricing, product features, or availability, we are all ears. Drop us an email with questions or inquiries, or you may even call us. Our team will be happy to address all your concerns.   
                  </p>
                  <p class="text-left mb-1">
                     Want to know more about our services? Do send your query, and we assure you to provide the best solution at the earliest.
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <owl-carousel-o [options]="cutomeoption">
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s1.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s2.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s1.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
               </owl-carousel-o>
            </div>
         </div>
      </div>
   </section>
   <section id="contact" class="contact sBG iArea2 text-white">
      <div class="container-fluid">
         <div class="row">
            <div class="d-flex align-items-center col-lg-6 p-0 bg-dark">
               <div class="imgMap">
                  <agm-map [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'roadmap'" [zoom]="4.5" style="height: 100%">
                  <agm-marker *ngFor="let data of getLatLng; let i = index" [latitude]="data.lat" [longitude]="data.lng" >
                  <agm-info-window [disableAutoPan]="true">
                     <span class="text-center">{{data.name}}</span>
                     <br>  
                     <span class="text-center">{{data.location}}</span>
                  </agm-info-window>
                  </agm-marker>
                  </agm-map>
               </div>
            </div>
            <div class="col-lg-6 p-5">
               <div class="large-title text-center">
                  <h2 class="mb-3 text-white">Get in Touch!</h2>
                  <p >We'd really like to hear from you!</p>
               </div>
               <form [formGroup]="contactForm" 
               (ngSubmit)="onContactus(contactForm.value)" ngNativeValidate>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label for="name">Your Name</label>
                     <input type="text" name="first_name" formControlName="first_name" class="text-white form-control bg-transparent" 
                        [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" id="first_name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                     <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.first_name.errors.required">Name is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label for="email">Your Email</label>
                     <input type="email" class="text-white form-control bg-transparent" name="email" formControlName="email" 
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" data-rule="email" data-msg="Please enter a valid email">
                     <div *ngIf="submitted && f.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-group">
                  <label for="subject">Subject</label>
                  <input type="text" class="text-white form-control bg-transparent" name="subject" formControlName="subject"
                     [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                  <div *ngIf="submitted && f.subject.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="f.subject.errors.required">Subject is required</div>
                  </div>
               </div>
               <div class="form-group">
                  <label for="subject">Location</label>
                  <select class="text-white form-control bg-transparent" formControlName="location" 
                     [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                     <option *ngFor="let item of location" class="text-dark" value="{{item.city_name}}" class="text-dark">{{item.address}}</option>

                     <!-- <option value="Atlanta GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                     <option value="Houston TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                     <option  value="North Bergen NJ"class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                     <option  value="Quakertown PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                     <option value="Windsor CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                  </select>
                  <div *ngIf="submitted && f.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="f.location.errors.required">Location is required</div>
                  </div>
               </div>
               <div class="form-group">
                  <label for="message">Message</label>
                  <textarea class="text-white form-control bg-transparent" name="message" rows="4" formControlName="message" 
                     [ngClass]="{ 'is-invalid': submitted && f.message.errors }" data-rule="required" data-msg="Please write something for us"></textarea>
                  <div *ngIf="submitted && f.message.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="f.message.errors.required">Message is required</div>
                  </div>
               </div>
               <div class="text-center d-flex justify-content-center">
                  <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Send Message<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
               </div>
               <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
                        <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
               </div>
               <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class="py-2 px-5 text-center">
                     <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}}</p>
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </section>
   <!-- Location -->
   <section class="py-5 bg-white">
      <div class="container-fluid px-4">
         <div class="row">
            <div class="col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2>IGM Locations</h2>
               </div>
            </div>
         </div>
         <div class="row justify-content-center mt-3 colorRepeat">
            <a  *ngFor="let item of location" href="javascript:void(0)" 
               class="rounded IShine col-sm-4 col-xl overflow-hd px-1 mb-2 flex-grow-1 cursor-none">
               <div class="rounded clrBox owlC2">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>{{item.city_name}}</h4>
                  <p style="clear: both;">
                     {{item.address}}
                  </p>
                  <p> 
                     <a  href="tel:{{item?.phone}}" class="text-light">
                        <i class="fa fa-phone  mb-1" aria-hidden="true"></i>  {{item.phone}}
                     </a>
                  </p>
            <p class="d-flex text-break align-items-start justify-content-center "> 
               <a href="mailto:{{item?.city_mail}}" class="text-light">
                  <i class="fa fa-envelope" aria-hidden="true"></i> {{item.city_mail}}
               </a>
            </p>
            </div>
            </a>            
         </div>
      </div>
   </section>
</main>
