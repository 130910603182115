import { Component, OnInit } from '@angular/core';
import { OwlOptions  } from 'ngx-owl-carousel-o';
import { ApiService,SeoServiceService } from '../service/index'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  customOptions: OwlOptions = {
    loop:true,
    margin:10,
      //animateOut: 'slideOutDown',
    //animateIn: 'flipInX',
      smartSpeed:450,
    //default settings:
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    //default settings:
    autoplay: true,
    autoplayTimeout: 4500,
    nav: false,
    autoplayHoverPause: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
  };
location = [];
quartsData = [];
sliderData = [];
loadmore=  false;
  constructor(private api: ApiService , private _seoService: SeoServiceService) { 
    this.sliderdata();
    this.getLocation();
    this.getQuartsProduct(0)
    this._seoService.updateTitle('Durable Quartz Products for Customized Project Needs | Coalesce Quartz');
    this._seoService.updateDescription('Browse an eclectic range of quartz products for residential and commercial spaces like kitchen, lobby, bathroom, bedroom, and more at the best prices.')
  }

  ngOnInit(): void {
  }

  
  getLocation() {
    // this.loadMore =  true;
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
          // this.loadMore =  false;
        } else {
          this.location =  [];
          // this.loadMore =  false;
        }
      }
    )
  }

  getQuartsProduct(offset) {
    this.api.fetchDataCosmos('specialproductlistquartz', {page:offset*6,website:'coalesce-quartz' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.quartsData =  res['product']
        } else {
          this.quartsData = [];
        }
      }
    )
  }

  sliderdata() {
    this.loadmore = true;
    this.api.fetchData('sliderdata',{website:'coalesce-quartz'},'GET').subscribe(
      res => {
        this.loadmore = false;
        if(res['status'] == 1) {
          this.sliderData = res['data']
        }else {
          this.sliderData = []
        }
      }
    )
  }

}
