<main>
   <section class="headInnP">
      <div class="container py-md-5 pt-3 pb-4">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
            </div>
         </div>
      </div>
   </section>
   <section class="bg-light py-md-5 py-3" *ngIf="productDetailcosmos">
      <div class="container-fluid" *ngIf="productDetailcosmos.product" >
         <div class="mb-3 row align-items-center mx-0 justify-content-between">
            <div class="col d-lg-flex justify-content-between align-items-center">
               <h1 class="h3 slider-heading-product quartzHeading" *ngIf="productDetailcosmos">
                  {{productDetailcosmos.product.ProductNameIgm}} <span *ngIf="productDetailcosmos?.product.discontinued == 1"> *</span>
               </h1>
               <ol class="breadcrumb bg-white justify-content-start mb-0 px-4" *ngIf="productDetailcosmos">
                  <li class="breadcrumb-item"><a [routerLink]="['/']" href="javascript:void(0)" class="text-dark"><i class="fa-24 fa fa-home"></i></a></li>
                  <li class="breadcrumb-item"><a routerLink="/{{this.cat_slug}}" href="javascript:void(0)" class="text-dark">
                     <span *ngIf="productDetailcosmos"> 
                     {{productDetailcosmos.product.category_name | uppercase}} 
                     </span>               
                     <span *ngIf="!productDetailcosmos"> 
                     N/A               
                     </span>  
                     </a>
                  </li>
                  <li class="breadcrumb-item"><a routerLink="/{{cat_slug}}/{{productDetailcosmos.product.productSlug}}" [queryParams]="{ id: this.id}" href="javascript:void(0)" class="text-dark">
                     <span *ngIf="productDetailcosmos"> 
                     {{productDetailcosmos.product.ProductNameIgm | uppercase}} 
                     </span>               
                     <span *ngIf="!productDetailcosmos"> 
                     N/A               
                     </span>  
                     </a>
                  </li>
               </ol>
            </div>
         </div>
         <div class="row align-items-start">
            <div class="col-lg-12 col-md-12">
               <div class="">
                  <div class="row align-items-start mx-0" *ngIf="productDetailcosmos && productDetailcosmos.product ">
                     <div class="col-xl-10 col-lg-9">
                        <ng-container class="big_slider_nav">
                           <owl-carousel-o [options]="customOptions" #productSlider>
                           <ng-template carouselSlide class="slider" *ngFor="let item of productDetailcosmos.product.images; let i = index"
                           [id]="item.image" > 
                           <div class="item" >
                              <div class="imgCenter" *ngIf="item.image && isBrowser">
                                 <ng-container >
                                    <lib-ngx-image-zoom 
                                    [thumbImage]=item.image
                                    [fullImage]= item.image
                                    [magnification]="1"
                                    [enableScrollZoom]="true"
                                    [enableLens]="true"
                                    [lensWidth]="200"
                                    [magnification]="2"
                                    (click)="onclickImage(item,i)"
                                    ></lib-ngx-image-zoom>
                                 </ng-container>
                              </div>
                           </div>
                           </ng-template> 
                           </owl-carousel-o>
                        </ng-container>
                     </div>
                     <div class="col-xl-2 col-lg-3">
                        <div class="sliderRV">
                           <owl-carousel-o [options]="customOptions2">
                              <ng-template carouselSlide class="slider" *ngFor="let item of productDetailcosmos.product.images; let i = index">
                                 <div class="w-100 row mb-2 mx-0 pointer">
                                    <div class="col-12 px-0" (click)="productSlider.to(item.image)">
                                       <div class="m-item item overflow-hidden" style="background-image: url('{{item.image}}')">
                                          <img src="{{item.image}}" class="d-none d-md-inline w-md-100">
                                          <div class="textImg d-none d-md-inline">{{item.option | uppercase}}</div>
                                       </div>
                                    </div>
                                 </div>
                              </ng-template>
                           </owl-carousel-o>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container mt-5">
               <div class="col-lg-12 col-md-12 mt-1" *ngIf="productDetailcosmos">
                  
                  <div class="row px-0 d-flex flex-wrap">

                     <div class="col-md-7">
                  <div>
                      <h5 class="mb-2" *ngIf="productDetailcosmos && productDetailcosmos.product.description_coalesce_quartz">About  <span *ngIf="productDetailcosmos"> 
                     {{productDetailcosmos.product.ProductNameIgm}} 
                     </span>   
                  </h5>
                     <div *ngIf="productDetailcosmos && productDetailcosmos.product.description_coalesce_quartz" class="col-md-12 px-0">
                        <!-- N/A -->
                        <div *ngIf="showDescription == false" [innerHtml]="productDetailcosmos?.product.description_coalesce_quartz  | slice:0:270">
                     </div>
                     <div *ngIf="showDescription == true" [innerHtml]="productDetailcosmos?.product.description_coalesce_quartz ">
                  </div>
               </div>
               <div class="col-md-12 px-0 text-right" *ngIf="productDetailcosmos && productDetailcosmos.product.description_coalesce_quartz">
                  <a href="javascript:void(0)" *ngIf="showDescription ==false" (click)="showDescription = true" class="mb-1 h5 text-theme-light text-underline">View More</a>
                  <a href="javascript:void(0)" *ngIf="showDescription ==true" (click)="showDescription = false" class="mb-1 h5 text-theme-light text-underline">View less</a>
               </div>
                  </div>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
               <video width="100%" controls autoplay loop="true">
                 <source src="assets/Calcatta-Sponda.mp4" type="video/mp4">
               </video>
            </div>
            </div>
            <hr *ngIf="productDetailcosmos && productDetailcosmos.product.description_coalesce_quartz">
            <div class="row px-2">
               <div class="col-md-12 px-0 mb-1 d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                  <div class="card p-0" style="max-width: 325px;">
                     <div class="d-flex card-body text-center p-0" *ngIf="qrdata && isBrowser">
                        <qrcode [qrdata]="qrdata" [width]="350" [errorCorrectionLevel]="'M'"></qrcode>
                        <button (click)="downloadQr()" class="ml-1 border-left btn btn-link">Download QR Code</button>
                     </div>
                  </div>
                  <a class="mt-3 mt-md-0 mx-3 btn btn-outlined btn-theme btn-lg d-inline-flex flex flex-wrap align-items-center" href="javascript:void(0)" data-toggle="modal" data-target="#OrderSampleModal"><i class="fa fa-th-large pr-2" aria-hidden="true" (click)="openForm()"></i>Order Sample</a>
               </div>
            </div>
            <div class="modal fade" id="OrderSampleModal" tabindex="-1" data-backdrop="static">
               <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                     <form [formGroup]="orderForm" 
                     (ngSubmit)="onOrder(orderForm.value)" ngNativeValidate>
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Order Sample</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm()">
                        <span aria-hidden="true">×</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="form-row">
                           <!--  <div class="col-md-12 form-group">
                              <hr class="my-2">
                              </div> -->
                           <div class="col-xl-6 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">First Name *</label>
                              <input type="text" name="name" class="form-control" data-rule="minlen:4" data-msg="Please enter at least 4 chars" 
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.fname.errors }" formControlName="fname">
                              <div *ngIf="ordersubmitted && f.fname.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.fname.errors.required"> First Name is required</div>
                              </div>
                           </div>
                           <div class="col-xl-6 col-md-6 form-group mb-1">
                              <label class="mb-1" for="email">Last Name *</label>
                              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': ordersubmitted && f.lname.errors }"
                                 formControlName="lname">
                              <div *ngIf="ordersubmitted && f.lname.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.lname.errors.required">Last Name is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">Phone *</label>
                              <input type="text" name="name" class="form-control" 
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.phone.errors }" formControlName="phone">
                              <div *ngIf="ordersubmitted && f.phone.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.phone.errors.required">Phone is required</div>
                                 <div *ngIf="f.phone.errors.pattern">Only Number  is required</div>
                                 <div *ngIf="f.phone.errors.minlength">Minimum 10 digit is required.</div>
                                 <div *ngIf="f.phone.errors.maxlength">Maximum 13 digits allowed.</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="email">Email *</label>
                              <input type="email" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.email.errors }" formControlName="email">
                              <div *ngIf="ordersubmitted && f.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.email.errors.required">Email is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">Company Name</label>
                              <input type="text" name="name" class="form-control"
                                 formControlName="company_name">
                           </div>
                           <div class="col-md-12 form-group">
                              <label class="mb-1" for="email">Address *</label>
                              <textarea type="email" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.address.errors }" formControlName="address"></textarea>
                              <div *ngIf="ordersubmitted && f.address.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.address.errors.required">Address is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="City">City *</label>
                              <input type="City" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.city.errors }" formControlName="city">
                              <div *ngIf="ordersubmitted && f.city.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.city.errors.required">City is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">State *</label>
                              <select type="text" name="name" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.state.errors }" formControlName="state">
                                 <option selected="selected" value="--Select--">--Select--</option>
                                 <option value="AL">AL</option>
                                 <option value="AR">AR</option>
                                 <option value="AZ">AZ</option>
                                 <option value="CA">CA</option>
                                 <option value="CO">CO</option>
                                 <option value="CT">CT</option>
                                 <option value="DC">DC</option>
                                 <option value="DE">DE</option>
                                 <option value="FL">FL</option>
                                 <option value="GA">GA</option>
                                 <option value="IA">IA</option>
                                 <option value="ID">ID</option>
                                 <option value="IL">IL</option>
                                 <option value="IN">IN</option>
                                 <option value="KS">KS</option>
                                 <option value="KY">KY</option>
                                 <option value="LA">LA</option>
                                 <option value="MA">MA</option>
                                 <option value="MD">MD</option>
                                 <option value="ME">ME</option>
                                 <option value="MI">MI</option>
                                 <option value="MN">MN</option>
                                 <option value="MO">MO</option>
                                 <option value="MS">MS</option>
                                 <option value="MT">MT</option>
                                 <option value="NC">NC</option>
                                 <option value="ND">ND</option>
                                 <option value="NE">NE</option>
                                 <option value="NH">NH</option>
                                 <option value="NJ">NJ</option>
                                 <option value="NM">NM</option>
                                 <option value="NV">NV</option>
                                 <option value="NY">NY</option>
                                 <option value="OH">OH</option>
                                 <option value="OK">OK</option>
                                 <option value="OR">OR</option>
                                 <option value="PA">PA</option>
                                 <option value="RI">RI</option>
                                 <option value="SC">SC</option>
                                 <option value="SD">SD</option>
                                 <option value="TN">TN</option>
                                 <option value="TX">TX</option>
                                 <option value="UT">UT</option>
                                 <option value="VA">VA</option>
                                 <option value="VT">VT</option>
                                 <option value="WA">WA</option>
                                 <option value="WI">WI</option>
                                 <option value="WV">WV</option>
                                 <option value="WY">WY</option>
                              </select>
                              <div *ngIf="ordersubmitted && f.state.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.state.errors.required">State is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="Country">Country  *</label>
                              <input type="Country" class="form-control" [readonly]="true"
                                 formControlName="country">
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">Zip/Postal Code *</label>
                              <input type="text" name="name" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.zip.errors }" formControlName="zip">
                              <div *ngIf="ordersubmitted && f.zip.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.zip.errors.required">Zip/Postal Code is required</div>
                                 <div *ngIf="f.zip.errors.pattern"> Only Number  is required</div>
                                 <div *ngIf="f.zip.errors.minlength">Minimum 5 digit is required.</div>
                                 <div *ngIf="f.zip.errors.maxlength">Maximum 5 digits allowed.</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="subject">Location *</label>
                              <select class=" form-control "
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.location.errors }" formControlName="location">
                                 <option *ngFor="let item of location" class="text-dark" value="{{item.city_name}}" class="text-dark">{{item.address}}</option>

                                 <!-- <option value="ATLANTA GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                                 <option value="HOUSTON TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                                 <option value="NORTH BERGEN NJ" class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                                 <option value="QUAKERTOWN PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                                 <option value="WINDSOR CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                              </select>
                              <div *ngIf="ordersubmitted && f.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.location.errors.required">Location is required</div>
                              </div>
                           </div>
                           <div class="col-xl-4 col-md-6 form-group mb-1">
                              <label class="mb-1" for="name">Customer Type *</label>
                              <select type="text" name="name" class="form-control"
                                 [ngClass]="{ 'is-invalid': ordersubmitted && f.occupation.errors }" formControlName="occupation">
                                 <option value="Home Owner">Home Owner</option>
                                 <option value="Fabricator">Fabricator</option>
                                 <option value="Designer">Designer</option>
                                 <option value="Archtect">Architect</option>
                                 <option value="Builder">Builder</option>
                                 <option value="Retailer/ Wholesaler">Retailer/ Wholesaler</option>
                                 <option value="Other">Other</option>
                              </select>
                              <div *ngIf="ordersubmitted && f.occupation.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.occupation.errors.required">Customer is required</div>
                              </div>
                           </div>
                           <div class="col-md-12 form-group">
                              <label class="mb-1" for="email">Message </label>
                              <textarea class="form-control"
                                 formControlName="message"></textarea>
                           </div>
                        </div>
                        <p class="h6 mb-0">*Sample dispatch is subject to the availability of the product in our inventory.</p>
                     </div>
                     <div class="modal-footer">
                        <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                           <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                              <div class="d-flex align-items-center">
                                 <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
                                 <p class="mb-0 mt-1 mr-3">Loading...</p>
                              </div>
                           </div>
                        </div>
                        <div *ngIf="ordermessage" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                           <div class=" py-2 px-5 text-center">
                              <p id="res_message mt-2 text-center" *ngIf="ordermessage" class=""> {{ordermessage}} </p>
                           </div>
                        </div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="orderForm-close" (click)="resetForm()">Close</button>
                        <button  class="btn btn-primary">Submit</button>
                     </div>
                     </form>
                  </div>
               </div>
            </div>
            <p></p>
            <div class="row" *ngIf="productDetailcosmos">
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.ProductColor">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Colors</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.ProductColor}} 
                        </span>
                     </p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.finish">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Finish</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.finish}} 
                        </span>
                     </p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.thickness">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Thickness</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{addSpace(productDetailcosmos.product.thickness)}} 
                        </span>
                     </p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.ProductGroup">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Product Group</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.ProductGroup}} 
                        </span>
                     </p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.ProductOrigin">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">County of Origin
                     </h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.ProductOrigin}} 
                        </span>
                     </p>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.Series">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Series</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.Series}} 
                        </span>
                     </p>
                  </div>
               </div>
               <!-- <div class="col-lg-4 col-sm-6 mb-0 p-2" [hidden]="!productDetailcosmos?.product.alias_name">
                  <div class="bg-white px-3 py-2">
                     <h5 class="mb-0">Also Known As</h5>
                     <p class="mb-0">
                        <span *ngIf="productDetailcosmos"> 
                        {{productDetailcosmos.product.alias_name}} 
                        </span>
                     </p>
                  </div>
               </div> --> 
               <ng-container *ngIf="productDetailcosmos?.product.extra_fields">
                  <div class="col-lg-4 col-sm-6 mb-0 p-2" *ngFor="let itemdata of productDetailcosmos?.product.extra_fields" [hidden]="!productDetailcosmos?.product.extra_fields">
                  <div class="bg-white px-3 py-2" *ngFor="let item2 of itemdata; let i = index" [hidden]="i >= 1"> 
                  <h5 class="mb-0">{{itemdata[0]}}</h5>
                  <p class="mb-0">
                     <span *ngIf="productDetailcosmos"> 
                     {{itemdata[1]}} 
                     </span>
                  </p>
            </div>
         </div>
         </ng-container>
         <div class="col-sm-12 mb-0 p-2" [hidden]="!productDetailcosmos?.product.usages">
            <div class="bg-white px-3 py-2">
               <h5 class="mb-0">Usages</h5>
               <p class="mb-0">
                  <span *ngIf="productDetailcosmos"> 
                  {{productDetailcosmos.product.usages}} 
                  </span>
               </p>
            </div>
         </div>
      </div>
      <div class="row" *ngIf="productDetailcosmos?.product.discontinued == 1">
         <div class="col-12">
            * Limited Stock
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12 mb-0">
            <hr>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModalLong-disclamer" [hidden]="!productDetailcosmos?.product.disclaimer" class="mb-0 h5 text-theme-light text-underline">Disclaimer</a>
         </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div  *ngIf="productDetailcosmos.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
         <div class=" bg-light py-2 px-5 text-center">
            <p class="mb-0 mt-1"> No product found.</p>
         </div>
      </div>
      <div class="fBtn1 ">
         <button type="button" class="shadow-lg btn btn-dark" (click)="shared.showFilter = true;shared.resetFilter =true" routerLink="/{{this.cat_slug}}"><i class="fa fa-filter"></i></button>
      </div>
   </section>
   <section class="pb-5 bg-light">
      <div class="container-fluid" *ngIf="productDetailcosmos && productDetailcosmos.related &&productDetailcosmos.related.length > 0">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">YOU MAY ALSO LIKE</h2>
               </div>
            </div>
         </div>
         <div class="d-flex row productRow mt-2 pl-4 justify-content-center">
            <ng-container *ngIf="productDetailcosmos && productDetailcosmos.related">
               <div class="IShine col-sm-3 overflow-hd px-1 mb-2 flex-grow-1"
                  *ngFor="let item of productDetailcosmos.related">
                  <div class="thumb" >
                     <a routerLink="/{{cat_slug}}/{{item.productSlug}}" *ngIf="item.thumbnail" href="javascript:void(0)" style="background-image: url('{{item.thumbnail}}');">
                     </a>                           
                     <a  routerLink="/{{cat_slug}}/{{item.productSlug}}" *ngIf="!item.thumbnail" class="img-placeholder" href="javascript:void(0)" style="background-image: url({{tUrl}}/placeholder.jpg);"></a>
                  </div>
                  <div class="pDetails coalDetail">{{item.ProductName}}</div>
               </div>
            </ng-container>
         </div>
      </div>
   </section>
   <section id="contact" class="contact sBG iArea2 text-white" *ngIf="productDetailcosmos">
      <div class="container-fluid py-5">
         <div class="row justify-content-center">
            <div class="col-xl-12 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3 text-white">ASK US ANYTHING, WE'RE HERE TO HELP!</h2>
               </div>
            </div>
            <div class="col-lg-6 mt-md-5 mt-3">
               <form [formGroup]="helpForm2" 
               (ngSubmit)="onsubmit(helpForm2.value)" ngNativeValidate class="w-100">
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">Your Name *</label>
                     <input type="text" name="name" class=" text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.name.errors }" formControlName="name">
                     <div *ngIf="submitted && g.name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.name.errors.required">Name is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="email">Your Email *</label>
                     <input type="email" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.email.errors }" formControlName="email">
                     <div *ngIf="submitted && g.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.email.errors.required">Email is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">Phone *</label>
                     <input type="text" name="name" class=" text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.phone.errors }" formControlName="phone">
                     <div *ngIf="submitted && g.phone.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.phone.errors.required">Phone is required</div>
                        <div *ngIf="g.phone.errors.pattern"> Only Number  is required</div>
                        <div *ngIf="g.phone.errors.minlength">Minimum 10 digit is required.</div>
                        <div *ngIf="g.phone.errors.maxlength">Maximum 13 digits allowed.</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="street">Street *</label>
                     <input type="text" class=" text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.street.errors }" formControlName="street">
                     <div *ngIf="submitted && g.street.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.street.errors.required">Street is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="city">City *</label>
                     <input type="text" class=" text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.city.errors }" formControlName="city">
                     <div *ngIf="submitted && g.city.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.city.errors.required">City is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">State *</label>
                     <input type="text" name="state" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.state.errors }" formControlName="state">
                     <div *ngIf="submitted && g.state.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.state.errors.required">State is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-xl-6 col-md-6 form-group mb-1 ">
                     <label class="mb-1" for="subject">Location *</label>
                     <select class=" text-white form-control bg-transparent "
                        [ngClass]="{ 'is-invalid': submitted && g.location.errors }" formControlName="location">
                        <option *ngFor="let item of location" class="text-dark" value="{{item.city_name}}" class="text-dark">{{item.address}}</option>

                        <!-- <option value="ATLANTA GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                        <option value="HOUSTON TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                        <option value="NORTH BERGEN NJ" class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                        <option value="QUAKERTOWN PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                        <option value="WINDSOR CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                     </select>
                     <div *ngIf="submitted && g.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.location.errors.required">Location is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="email">Customer Type *</label>
                     <select class="text-white form-control bg-transparent" name="occupation"
                        [ngClass]="{ 'is-invalid': submitted && g.area.errors }" formControlName="area">
                        <option class="text-dark" value="">Select</option>
                        <option class="text-dark" value="Home Owner">Home Owner</option>
                        <option class="text-dark" value="Fabricator">Fabricator</option>
                        <option class="text-dark" value="Designer">Designer</option>
                        <option class="text-dark" value="Archtect">Architect</option>
                        <option class="text-dark" value="Builder">Builder</option>
                        <option class="text-dark" value="Retailer/ Wholesaler">Retailer/ Wholesaler</option>
                        <option class="text-dark" value="Other">Other</option>
                     </select>
                     <div *ngIf="submitted && g.area.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.area.errors.required">Customer Type is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-group">
                  <label class="mb-1" for="message">Message *</label>
                  <textarea class=" text-white form-control bg-transparent"
                     [ngClass]="{ 'is-invalid': submitted && g.message.errors }" formControlName="message"></textarea>
                  <div *ngIf="submitted && g.message.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="g.message.errors.required">Message is required</div>
                  </div>
               </div>
               <div class="text-center d-flex justify-content-center">
                  <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Submit  <i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
               </div>
               <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
                        <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
               </div>
               <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" py-2 px-5 text-center">
                     <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </section>
   <div *ngIf="loadMore && !productDetailcosmos" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center bg-light">
      <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
         <div class="d-flex align-items-center">
            <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
            <p class="mb-0 mt-1 mr-3">Loading...</p>
         </div>
      </div>
   </div>
</main>
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" id="order-pop" data-toggle="modal" data-target="#order-form-result">
Launch demo modal
</button>
<div class="modal fade" id="order-form-result" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content ">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            Thanks for choosing us and reaching out to us, our team will be happy to assist you and get back to you shortly.
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" id="inv-image-pop" data-toggle="modal" data-target="#inventory-image-pop">
Launch demo modal
</button>
<!-- Modal -->
<div class="modal fade" id="inventory-image-pop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"data-backdrop="static" aria-hidden="true">
   <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content" *ngIf="selectedImage ">
         <div class="modal-header py-2">
            <h5 class="modal-title align-items-center" id="exampleModalLongTitle" *ngIf="productDetailcosmos && productDetailcosmos.product "> 
               {{productDetailcosmos.product.ProductNameIgm}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body p-0">
            <div class="zoom-inv-img-div position-relative" *ngIf="selectedImage">
               <lib-ngx-image-zoom
               [thumbImage]=selectedImage.image
               [fullImage]=selectedImage.image
               [magnification]="1"
               [enableScrollZoom]="true"
               [enableLens]="true"
               [lensWidth]="200"
               [magnification]="2"
               ></lib-ngx-image-zoom>
               <div class="owl-nav d-flex align-items-center justify-content-between" *ngIf="selectedImage['length']  != 1">
                  <button type="button" role="presentation" class="btn btn-light owl-prev"
                  [disabled]=" selectedImage['index'] == 0" (click)="onclickArraow(selectedImage, 'pre')">
                  <span aria-label="Previous"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                  </button>
                  <button type="button" role="presentation" class="btn btn-light owl-next"
                  [disabled]="selectedImage['index']+1 == selectedImage['length']" (click)="onclickArraow(selectedImage, 'next')">
                  <span aria-label="Next"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>