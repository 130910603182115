import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { ProjectComponent } from './project/project.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { LocationComponent } from './location/location.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ProductpageComponent } from './productpage/productpage.component';
import { CompanyComponent } from './company/company.component';
import {  PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CarouselModule  } from 'ngx-owl-carousel-o';
import { AgmCoreModule } from '@agm/core';
import { QuartzlistingComponent } from './quartzlisting/quartzlisting.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QRCodeModule } from 'angularx-qrcode';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { AutofocusDirective } from './autofocus.directive';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatterService } from './service/ngb-date-custom-parser-formatter.service';
import {Location} from '@angular/common';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LightboxModule } from 'ngx-lightbox';
import { WarrantyregistrationComponent } from './warrantyregistration/warrantyregistration.component';
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CustomerServiceComponent,
    ProjectComponent,
    ProductdetailComponent,
    LocationComponent,
    ContactComponent,
    HomeComponent,
    ProductpageComponent,
    CompanyComponent,
    PrivacyPolicyComponent,
    QuartzlistingComponent,
    AutofocusDirective,
    BookAppointmentComponent,
    PagenotfoundComponent,
    WarrantyregistrationComponent
      
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCECNx6YKAjaYfP9Eq7FXAMB1QmjUKvMZk'
    }),
    InfiniteScrollModule,
    NgxImageZoomModule,
    QRCodeModule,
    NgxMaterialTimepickerModule,
    NgbModule,
    LightboxModule,
    NgxCaptchaModule
  ],
  providers: [  { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatterService }, Meta, Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
