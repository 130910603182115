<main>
   <section class="tringle AraAbt secPad2 bg-light">
      <div class="container py-md-5 py-3">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="capAbt">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <h2 class="mb-3">About Company</h2>
                  </div>
                  <p class="text-left mb-1">
                     At CoalesceQuartz, with a network of best suppliers, we source the highest quality quartz stones. From selection to execution, we handle the entire process with safety and without misspecification. We maintain quality and control without any risk and have clientele from different parts of the country.
                  </p>
                  <p class="text-left mb-1">
                     We keep our customers informed with detailed communication and offer flexible delivery providing exquisite customer service. Do take a look at our endless range of quartz products and embrace your living space. We design the best for your space and stay updated with the latest technologies.
                  </p>
                  <p class="text-left mb-1">
                     Are you looking for a company that deals in premium quartz products? Come and visit our store and choose elegant quartz designs for your residence and offices. Feel free to reach us via email or phone call. We would be happy to assist you.
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <owl-carousel-o [options]="cutomeoption">
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s1.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s2.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s1.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>
                  </ng-template>
               </owl-carousel-o>
            </div>
         </div>
      </div>
   </section>
   <section class="bg-dark shadow-lg" id="story">
      <div class="container-fluid ml-0">
         <div class="row">
            <div class="col-md-6 col-lg-4 px-sm-0">
               <owl-carousel-o [options]="cutomeoption2" >
                  <ng-template carouselSlide>
                     <div class="item">
                        <img src="{{tUrl}}/products/dolomite.jpg" class="img-fluid" />
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="item">
                        <img src="{{tUrl}}/products/granite.jpg" class="img-fluid" />
                     </div>
                  </ng-template>
               </owl-carousel-o>
            </div>
            <div class="py-5 d-flex align-items-center py-5 col-md-6 col-lg-8 px-sm-0">
               <div class="card-detail__wrapper shadow-lg text-dark">
                  <div class="card-detail">
                     <article class="card card-text-2">
                        <div class="card-header bg-transparent pb-0 border-0 bg-white">
                           <div class="large-title text-left">
                              <h2 class="mb-0">Our Story</h2>
                           </div>
                        </div>
                        <div class="card-body bg-transparent pt-2">
                           <p class="text-left mb-2">
                              Since we founded CoalesceQuartz, we have been actively involved in several commercial and residential projects. We have experts that have specialization in fabrication and installation. We started at a low profile and are now considered to be the leading companies. When it comes to buying a stone, particularly quartz, you have multiple choices, and we would like to highlight why we are better than others.   
                           </p>
                           <p class="text-left mb-2">
                              Visit us, and we will take you to our journey that starts with product selection and then to installation so that you could believe in us more.
                           </p>
                        </div>
                     </article>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section id="careers" class="IShine iArea carImg" style="background-image: url('{{tUrl}}/career.jpg')">
      <div class="py-5 container">
         <div class="row align-items-start justify-content-center">
            <div class="col-xl-12 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">Careers</h2>
               </div>
            </div>
            <div class="col-xl-12 col-md-12 text-center">
               <p class="text-left mb-1">Are you interested in joining our team? We are looking for talented professionals who are committed to delivering customer satisfaction and are dedicated to bringing the best in the business. We are committed to helping you reach your potential. Check out the current openings here! </p>
            </div>
            <div class="col-xl-12 col-md-12 text-center">
               <h2 class="text-left mt-3">Current Opening</h2>
               <p class="text-left mb-3">
                  We sincerely welcome your job applications as we're always looking for well-experienced and skilled professionals. Please do share your resume with us at <a href="career@mailto:coalescequartz.com" class="big">career@coalescequartz.com</a>.
               </p>
               <table class="table table-light shadow-lg text-left">
                  <thead class="thead-dark">
                     <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Job Distribution</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <th scope="row">1</th>
                        <td>Sales officer Distribution</td>
                        <td>This is a front line sales position within IGM, to be based at assigned area office.</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </section>
   <section class="text-dark">
      <div class="pt-5 container">
         <div class="row justify-content-around align-items-start">
            <div class="col-md-12 col-lg-12">
               <div class="about-content-right mb-md-4 mb-lg-0">
                  <div class="large-title text-center">
                     <h2 class="mb-3">Professionals</h2>
                  </div>
                  <p>Quartz is a top choice among different industry professionals like architects, designers, fabricators, and more. It offers outstanding features and stands out from other natural stones. It is way more durable than other stones and looks attractive forever. </p>
                  <p>The quartz stone is a good choice for the kitchen and bathroom space, and designing with quartz is easier than with natural stone. It contains resins and is essentially indestructible, making it the best choice for professionals. It is engineered and thus has a smoother non-porous surface. Another reason why quartz is a preferred choice is a perfect hygiene surface. It doesn’t need to be sealed and doesn’t damage the surface.</p>
                  <p>We, at CoalesceQuartz, offer the professionals a broad range of durable, budget-friendly, and low-maintenance quartz products. So why not choose one as what could be better than quartz since it features all these properties and also lasts forever?</p>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid"  id="Professionals">
         <div class="mt-4 row justify-content-around align-items-start" id="accordion">
            <div class="col-lg-12 d-flex flex-wrap px-0">
               <div class="col-lg-3 col-md-6 col-sm-12 px-0  color-1 color-tab-2">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingOne">
                        <h5 class="mb-0">
                           <button id="Architect" class="ensQuaBlk py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tUrl}}/icon/architect-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Architect</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0 colr2 color-tab-1">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingTwo">
                        <h5 class="mb-0">
                           <button id="Designer" class="ensQuaBlk py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tUrl}}/icon/interior-design-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Designer</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0 color-1 color-tab-1">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingThree">
                        <h5 class="mb-0">
                           <button id="Kitchen" class="ensQuaBlk py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tUrl}}/icon/kitchen-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Kitchen and Bath Studios</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0 colr2 color-tab-2">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingFour">
                        <h5 class="mb-0">
                           <button id="Fabricators" class="ensQuaBlk py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tUrl}}/icon/fabric-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Fabricators</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
            </div>
            <div class="w-100 px-0">
               <div class="col-lg-12 px-0">
                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>Architects from around the world choose quartz for enhancing traditional homes and bringing life to the living spaces. We provide them high-quality quartz products for modern and traditional designs since it is a combination of strength and beauty.</p>
                        <p>Architects love quartz because of its exceptional properties, and it resists staining better than granite and marbles. Also, as quartz stone is beautiful and comes in an array of colors and designs, it is unique and offers exceptional durability. It interacts with the light as glass does and has an appealing appearance that can last forever.</p>
                        <p>At Coalesce Quartz, we have a range of quartz products that can be matched with any setting, be it a bathroom or kitchen. Get in touch for more details!</p>
                     </div>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion" style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>Due to its high-performance, durability, and elegant look, the quartz stone is the finest choice of designers. It is a go-to choice for designers to create or redesign residential and commercial spaces.</p>
                        <p>The quartz is non-porous and endures in different environments. Also, it is easy to maintain and doesn’t need extra care. The wide assortment of colors not only appeals to the designers but also a variety of finishes make it beautiful and everlasting. Furthermore, it looks more appealing than other stones and gives a bold and seamless look.</p>
                        <p>If you need a quartz stone for your next design project, our Coalesce Quartz team can guide you with choosing the right product based on your project.</p>
                     </div>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion" style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>When it comes to the kitchen, you want it to look beautiful and cleaned every time. For this, you look for a stone that is maintenance-free, durable, and can be customizable according to the requirements. You spend most of your time in the kitchen, so this space should always look graceful and clean.  </p>
                        <p>Also, when guests arrive, the first thing they note is how your kitchen and bathroom look. Well, the way these spaces look says a lot about your choices. No matter what color you have selected for other areas, quartz complements every space. It is less likely to crack and is an excellent choice for the kitchen and bathroom. </p>
                        <p>Our range of quartz products is finished properly and looks exquisite when installed. Be it a kitchen or bathroom: the quartz product looks perfect for these spaces and can be customized to match the other settings.</p>
                     </div>
                  </div>
                  <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion" style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>With modern facilities, we offer premium materials at reasonable prices. With years of experience in the quartz stone industry, Coalesce Quartz is one of the leading companies around. We have an excellent range of quartz stone products and services and work on the latest technologies.</p>
                        <p>Available in different sizes, our quartz products can be installed and fabricated easily without any hassle.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>