<header class="hedDes" id="hedDes">
    <a class="navbar-brand p-0 fixed-logo ml-3" routerLink="/">
      <img src="{{tUrl}}/logo.png" class="img-fluid"/>
   </a>
    <nav class="p-0 navbar navbar-expand-lg navbar-dark mxBGTL">
       <div class="container-fluid pl-0">
          <button class="navbar-toggler" type="button" id="collapse-btn-nav" data-toggle="collapse" data-target="#collapsingNavbar">            
            <span class="navbar-toggler-icon"></span>
          </button>                  
          <div class="navbar-collapse collapse" id="collapsingNavbar">
             <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown mDD" (mouseover)="showWHvr(1)" (mouseout)="sAllWhDS()">
                   <a class="py-3 nav-link px-1 px-lg-2 text-black arrow-toggle" 
                    routerLink="quartz" data-toggle="dropdown" (click)="chaneOfRout()">Quartz                      
                  </a>
                   <button class="mob-toggle btn btn-link px-4" (click)="mClick(1)">
                    <i aria-hidden="true" class="fa fa-caret-down text-dark"></i></button>
                   <div class="dropdown-menu dLarge animated fadeInDown" [ngStyle]="hovDID === 1 ? hovStyle : defStyle">
                      <div class="d-flex flex-wrap flex-md-nowrap">
                         <div class="min-col col-md-12 col-12 px-0">
                            <h6 class="title">Color</h6>
                             <ul class="list-unstyled list_color  mt-3">
                                <li class="mb-2 mr-2"><a (click)="onClickColor('all'); hovDID=''; chaneOfRout()" routerLink="quartz">
                                    <span class="color_small_box"></span>
                                     <span class="color_small_txt">All</span>
                                    </a>
                                 </li>
                                  <li *ngFor="let item of cosmosFilter" class="mb-2 mr-2"><a (click)="onClickColor(item.color);hovDID='';chaneOfRout()" routerLink="quartz">
                                  <span class="color_small_box" [ngClass]="item.color"></span>
                                   <span class="color_small_txt">{{item.color}}</span>
                                  </a>
                               </li>                                 
                            </ul>
                         </div>
                      </div>
                      <!-- end row -->
                   </div>
                   <!-- dLarge.// -->
                </li>
                <li class="nav-item dropdown mDD" (mouseover)="showWHvr(2)" (mouseout)="sAllWhDS()">
                   <a class="py-3 nav-link px-1 px-lg-2 text-black arrow-toggle" routerLink="/projects" data-toggle="dropdown" (click)="chaneOfRout()">Projects </a>
                  <button class="mob-toggle btn btn-link px-4" (click)="mClick(2)">
                    <i aria-hidden="true" class="fa fa-caret-down text-dark"></i></button>
                   <div class="dropdown-menu dLarge animated fadeInDown" [ngStyle]="hovDID ===2 ? hovStyle : defStyle">
                      <div class="d-flex flex-wrap flex-md-nowrap">
                         <div class="min-col col-sm-6 col-12 px-2">
                            <h6 class="title">Residential</h6>
                            <div class="row mx-0">
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'residential'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/slider/1.jpg"/>
                                        </div>
                                        Kitchen
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'residential'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/slider/2.jpg"/>
                                        </div>
                                        Bathroom
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'residential'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/1.png"/>   
                                        </div>
                                        Living Space
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'residential'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/2.png"/>
                                        </div>
                                        Bedroom
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'residential'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/1.png"/>
                                        </div>
                                        Landry Room
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <!-- end col-3 -->
                         <div class="min-col col-sm-6 col-12 px-2">
                            <h6 class="title">Commercial</h6>
                            <div class="row mx-0">
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'commercial'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/1.png"/>
                                        </div>
                                        Reception
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'commercial'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/2.png"/>
                                        </div>
                                        Lobby
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-12 card mb-3 px-0 pointer" (click)="hovDID='';chaneOfRout()" [routerLink]="['projects']" [fragment]="'commercial'">
                                  <div class="row no-gutters">
                                     <div class="col-md-12 d-flex align-items-center">
                                        <div class="cardfix1">
                                           <img src="{{tUrl}}/newColor/1.png"/>
                                        </div>
                                        Waiting Area
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                         <!-- end col-3 -->
                      </div>
                      <!-- end row -->
                   </div>
                   <!-- dLarge.// -->
                </li>
                <li class="nav-item dropdown mDD" (mouseover)="showWHvr(3)" (mouseout)="sAllWhDS()">
                   <a class="py-3 nav-link px-1 px-lg-2 text-black arrow-toggle" routerLink="/customerService" (click)="hovDID='';chaneOfRout()" data-toggle="dropdown">Customer Service</a>
                    <button class="mob-toggle btn btn-link px-4" (click)="mClick(3)">
                    <i aria-hidden="true" class="fa fa-caret-down text-dark"></i></button>
                   <div class="dropdown-menu dLarge animated fadeInDown" [ngStyle]="hovDID === 3 ? hovStyle : defStyle">
                      <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                         <div class="min-col col-sm-6 col-12 mb-2 px-2">
                            <a class="text-dark card py-2 align-items-center" (click)="hovDID='';chaneOfRout()" [routerLink]="['where-to-buy']">
                               <div class="mb-2 text-center">
                                  <i class="fa fa-map-pin fa-2x"></i>
                               </div>
                               WHERE TO BUY
                            </a>
                         </div>
                         <div class="min-col col-sm-6 col-12 mb-2 px-2">
                            <a class="text-dark card py-2 align-items-center" (click)="hovDID='';chaneOfRout()" [routerLink]="['contact']">
                               <div class="mb-2 text-center">
                                  <i class="fa fa-phone fa-2x"></i>
                               </div>
                               Contact
                            </a>
                         </div>
                      </div>
                      <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                         <div class="min-col col-sm-6 col-12 mb-2 px-2">
                            <a class="text-dark card py-2 align-items-center" (click)="hovDID='';chaneOfRout()" [routerLink]="['customerService']" [fragment]="'care'">
                               <div class="mb-2 text-center">
                                  <i class="fa fa-headphones fa-2x"></i>
                               </div>
                               Care and Warranty
                            </a>
                         </div>
                         <div class="min-col col-sm-6 col-12 mb-2 px-2">
                            <a class="text-dark card py-2 align-items-center" (click)="hovDID='';chaneOfRout()" [routerLink]="['customerService']" [fragment]="'faq'">
                               <div class="mb-2 text-center">
                                  <i class="fa fa-question fa-2x"></i>
                               </div>
                               FAQ
                            </a>
                         </div>
                      </div>
                      <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                        <div class="min-col col-sm-12 mb-2 px-2">
                          <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0"  (click)="hovDID='';chaneOfRout()" [routerLink]="['book-appointment']">
                             <div class="mb-1 text-center">
                                <i class="fa fa-newspaper-o fa-2x text-dark"></i>
                             </div>
                             <div class="fontSt1 text-dark" href="javascript:void(0)">Book An Appointment
                             </div>
                          </a>
                       </div>
                    </div>
                      <!-- end row -->
                   </div>
                   <!-- dLarge.// -->
                </li>
             </ul>
          </div>
          <ul class="ml-auto sNet nav">             
             <li><a class="text-dark d-flex align-items-center" href="https://www.facebook.com/CoalesceQuartz/" target="_blank"><i class="fa fa-facebook"></i></a></li>
             <li><a class="text-dark d-flex align-items-center" href="https://www.pinterest.com/CoalesceQuartz" target="_blank"><i class="fa fa-pinterest-square"></i></a></li>
             <li><a class="text-dark d-flex align-items-center" href="https://www.instagram.com/coalescequartz" target="_blank"><i class="fa fa-instagram" target="_blank"></i></a></li>
             <li class="apple" *ngIf="shared.currenturl != '/quartz'" (click)="openSearchForm()"><a class="d-flex align-items-center" data-toggle="modal" data-target="#myModal"><i class="fa fa-search"></i></a></li>
          </ul>
       </div>
    </nav>   
 </header>
 <div class="modal" id="myModal">
   <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
       <!-- Modal Header -->
       <div class="modal-header">
         <h4 class="modal-title">What are you looking for...</h4>
         <button type="button" class="close" data-dismiss="modal">&times;</button>
       </div>
       <!-- Modal body -->
         <div class="modal-body">
            <div class="w-100 SrhCon">
               <form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)" ngNativeValidate>
                  <div class="finder">
                     <div class="fOuter">
                           <div class="fInner">
                              <input class="fInput" type="text" id="search_input" formControlName="search" (keyup)="onSearch($event)" name="q" placeholder="Search your Favorite slabs...">
                              <div *ngIf="!searchedPro" (click)="onSubmit(searchForm.value)" class="fIcon" ref="icon">
                              </div>
                           <div *ngIf="searchedPro" (click)="clearSearch()">
                              <i class="fa fa-times fa-2x"></i>
                           </div>
                        </div>
                     </div>
                  </div>
                  <ul class="list-group" *ngIf="searchedPro">
                     <li class="list-group-item" *ngFor="let item of searchedPro">
                        <a routerLink="/{{item.category_slug}}/{{item.productSlug}}" (click)="closeModel()">
                        {{item.ProductName}}
                        </a>
                     </li>
                  </ul>
               </form>                
            </div>
         </div>
       <!-- Modal footer -->
         <div class="modal-footer border-0">
            <button type="button" class="btn btn-coals" id="dismis-model" data-dismiss="modal">Close</button>
         </div>
      </div>
   </div>
 </div>