import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { searchForm } from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-quartzlisting',
  templateUrl: './quartzlisting.component.html',
  styleUrls: ['./quartzlisting.component.css']
})
export class QuartzlistingComponent implements OnInit {
  tUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  searchForm = searchForm;
  flag;
  locationData;
  filters = [];
  selectedcat;
  selectedcatslug;
  more;
  counter = 0
  params;
  searchedPro;
  location_slug;
  cat_slug;
  filtersdata;
  loadMore = false;
  submitted: boolean= false;
  showFilter = false;
  quartsData
  cosmosFilter;
  view;

  constructor(private fb: FormBuilder, private api: ApiService, public shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router,private _seoService: SeoServiceService ) { 
      this.flag = undefined;
      this.searchForm = this.fb.group({
        search: [null, ''],
      });  
      this.searchForm.patchValue({
        search:null
      })
      let data = this.router.url.split("/");
      localStorage.setItem('pro_catagory',data[1]);
      // localStorage.setItem('pro_catagory',data[2]);
      this.loadMore = false;
      this.location_slug = data[1];
      this.cat_slug = data[2];
      this.counter = 0;
      this.quartsData = undefined
      this.showFilter = false;
      this.cosmosFilter = undefined;
      // this.getCategory();
      // this.shared.selectedlocation = null;
      this.searchedPro = undefined;
      this.submitted = false
      this.filters = [];
      this.cosmosFilter = undefined;
      this.getAllQuartzFilter();
      this.view = '';
      if(this.shared.showFilter == true) {
        let data = localStorage.getItem('color');
        // if(data != 'all' && this.showFilter == false && this.shared.changeFilter == false) { 
        // }
        this.showFilter = true;
        this.shared.showFilter = false;
      }
      this._seoService.updateTitle('Diverse Quartz Product Range for Residential & Commercial Settings | Coalesce Quartz');
      this._seoService.updateDescription('Right from Alaska White CQ to Calacatta Artemis, we offer a great variety of quartz products to match your settings and transform your space and settings.')
    }
    

  ngOnInit(): void {
    if(this.shared.resetFilter== true){
      this.shared.resetFilter = undefined;
      this.showFilter = true;
      let data = localStorage.getItem('filtersData');
      this.filters = (data && data.length >0) ?  JSON.parse(data): [];
      if(  this.filters.length > 0) {
      setTimeout(()=> {
          localStorage.removeItem("filtersData");
          this.searchBycosmosfilter()
        },2000)
      }else{
        this.getQuartsProduct(0);
      }

    } else if(this.shared.searchedKey) {
      this.searchForm.patchValue({
        search: this.shared.searchedKey
      })
      this.flag = 'search';
      this.searchBycosmosfilter();
      this.shared.searchedKey = undefined
    } else 
    if(localStorage.getItem('color') != null && !this.shared.changeFilter) {
      let data = localStorage.getItem('color');
      console.log(data);
      if(data != 'all') {
        this.showFilter = true;
        setTimeout(() => {
          if(this.filters.length == 0) {
            document.getElementById('headingTwo').click()          
          }
          this.selecCosmosFliter({color:data}, 'color')
          localStorage.removeItem("color");
        }, 2000)
      } else {
        this.filters = [];
        this.getQuartsProduct(0);
      }
    } else {
      this.getQuartsProduct(0);
    }
  }

  ngDoCheck() {

    if(this.shared.changeFilter == true  ) {
      this.shared.changeFilter = undefined;
      this.showFilter = true;
      let data = localStorage.getItem('color');
      if(data != 'all') {
        setTimeout(() => {
          if(this.filters.length == 0) {
            document.getElementById('headingTwo').click()          
          }       
         this.selecCosmosFliter({color:data}, 'color')
         localStorage.removeItem("color");
       }, 2000)
      }else {
        this.filters = [];
        this.getQuartsProduct(0);
      }
    }
  }

  ngAfterViewInit(){
  }

 
  get f() { return this.searchForm.controls; }

  onSearch(e) {
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
    this.searchedPro = undefined;
    let value = e.target.value.trim();
      this.searchedPro = undefined;
      this.quartsData = undefined;
      if(value.length >2) {
        // this.filters = [];
        // this.loadMore = true;
        this.flag = 'search';
        this.searchBycosmosfilter()
        // let formData: FormData = new FormData(); 
        // formData.append('name',  value ); 
        // formData.append('website',  'igm' ); 
      //       this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
      //   res => {
      //     if(res['status'] == 1) {
      //       this.loadMore = false;
      //       this.searchedPro = res['product']
      //     } else {
      //       this.loadMore = false;
      //       this.searchedPro = []
      //     }
      //   }
      // )

       }
       if(value.length == 0) {
         this.flag = undefined;
         if(this.filters.length == 0) {
          this.searchForm.patchValue({
            search:null
          })
          this.counter = 0;
      
            this.getQuartsProduct(0)
        }else {
          this.searchBycosmosfilter();
        }
       }
  }

  clearSearch() {
    this.searchedPro = undefined;
    this.flag = undefined;
    this.searchForm.patchValue({
      search:null
    })
    if(this.filters.length == 0) {
      this.counter = 0;
  
        this.getQuartsProduct(0)
    }else {
      this.searchBycosmosfilter();
    }

  }

  onScrollQuarts() {
    if(this.more != 0 && this.loadMore == false) {
      this.getQuartsProduct(this.counter)
      this.loadMore = true;
    }
  }

  getQuartsProduct(offset) {
    this.loadMore = true;
    this.searchedPro = undefined;
    if(offset == 0) {
      this.quartsData = undefined;
      this.counter = 0;
    }
    this.counter++
    this.api.fetchDataCosmos('specialproductlistquartz', {page:offset*6,website:'coalesce-quartz' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.more  = res['more']
          this.quartsData =  this.quartsData == undefined ? []:this.quartsData
          res['product'].map(data => {
            if(data.images.length > 5){
              data.images = data.images.slice(0, 5);
            }
          })
          this.quartsData = this.quartsData.concat( res['product']);
          this.loadMore = false;
        } else {
          this.quartsData = [];
          this.loadMore = false;
        }
      }
    )
  }

  getAllQuartzFilter(){
    // https://staging.cosmosgranite.com/api/getAllFilter
    this.api.fetchDataCosmos('getAllFilter',{website:'coalesce-quartz'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
       this.cosmosFilter = res['filter']
        } else {
       
        }
      }
    )
  }

  

  scrollTO(){
    setTimeout(() => {
      var elmnt = document.getElementById("accordion");
   let pos =  document.documentElement.scrollTop || document.body.scrollTop;
      if(pos < elmnt.scrollHeight) {
        elmnt.scrollIntoView();
      }

    },200)
  }
  

  selecCosmosFliter(item, type){
    let name  = '';
    let obj
    if(type == 'color') {
       obj = {
        type: type,
        name: item.color
      }
    } else {
       obj = {
        type: type,
        name: item
      }

    }

    if(type == 'color'  || type == 'Series') { 
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
    }
    let y;
    if(type == 'thickness') {
      y = -1;
    } else {
      y  = this.filters.findIndex(f => f.type == type);
    }
  
    let x  = this.filters.findIndex(f => f.name == item);
    if( y == -1 && x == -1) {
      this.filters.push(obj)
      this.searchBycosmosfilter()
    }
  }
  searchBycosmosfilter(){
    let color = [];
    let thikness = [];
    let image_option = [];
    let usage = [];
    let Series = [];
    this.searchedPro = undefined;
    this.quartsData  = undefined;
    let name = this.searchForm.value.search ? this.searchForm.value.search : ''

    this.filters.map((f,i) => {
      if(f.type == 'color') {
       let x = this.cosmosFilter['color'].findIndex(x => x.color == f.name)
       if(x != -1) {
         color.push(this.cosmosFilter['color'][x]['id'])

       }
      }
      // if(f.type == "category") {
      //   category.push(f.name)
      // }
      if(f.type == "thickness") {
        thikness.push(f.name)
      }
      if(f.type == "filter_by_images") {
        image_option.push(f.name)
      }
      if(f.type == "usage") {
        usage.push(f.name)
      }
      if(f.type == "Series") {
        Series.push(f.name)
      }
      this.loadMore = true;
      if((i+1) == this.filters.length) {
        // let formData: FormData = new FormData(); 
        let formData: FormData = new FormData(); 
        formData.append('color_name',  color.toString() ); 
        formData.append('image_option',  image_option.toString()); 
        // formData.append('website',  'igm' ); 
        formData.append('usages', usage.toString()); 
        formData.append('thikness', thikness.toString() ); 
        formData.append('series', Series.toString() ); 
        formData.append('name', name ); 
        formData.append('website', 'coalesce-quartz' ); 
        this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
        res => {
          if(res['status'] == 1) {
            this.loadMore = false;
            res['product'].map(data => {
              if(data.images.length > 5){
                data.images = data.images.slice(0, 5);
              }
            })
            this.searchedPro = res['product']
          } else {
            this.loadMore = false;
            this.searchedPro = []
          }
        }
      )
      }
    }) 
    if(this.filters.length == 0 && this.searchForm.value.search && this.searchForm.value.search.length > 0) {
      let formData: FormData = new FormData(); 
      formData.append('color_name',  color.toString() ); 
      formData.append('image_option',  image_option.toString()); 
      // formData.append('website',  'igm' ); 
      formData.append('usages', usage.toString()); 
      formData.append('thikness', thikness.toString() ); 
      formData.append('series', Series.toString() ); 
      formData.append('name', name ); 
      formData.append('website', 'coalesce-quartz' ); 

      this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
      res => {
        if(res['status'] == 1) {
          this.loadMore = false;
          res['product'].map(data => {
            if(data.images.length > 5){
              data.images = data.images.slice(0, 5);
            }
          })
          this.searchedPro = res['product']
        } else {
          this.loadMore = false;
          this.searchedPro = []
        }
      }
    )
    }
  }
  
  clearcosmosFilter(){
    this.searchedPro = undefined;
    this.filters = [];
    if(this.searchForm.value.search && this.searchForm.value.search.length > 0) {
      this.searchBycosmosfilter()
      
    } else {
      this.getQuartsProduct(0)
      this.counter =0;

    }
  }

  removecosmosFilter(x) {
    this.filters.splice(x,1);
    if(this.filters.length == 0) {
      this.clearcosmosFilter();
    }else {
      this.loadMore = true;
      this.flag = 'search'
      this.searchBycosmosfilter()
    }
  }
  
  checkcosmosFilter(event, key, type) {
  
  
    let obj = {
      type: type,
      name: key
    }
    if(type == 'filter_by_images'  && event.target.checked) { 
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
    }
    let y;
    if(type == 'usage') {
      y = -1;
    } else {
      y  = this.filters.findIndex(f => f.type == type);
    }
   

    if(y == -1 && event.target.checked) {
      this.filters.push(obj)
    } else {
      let x  = this.filters.findIndex(f => f.name == key);
      this.filters.splice(x,1);
    }
    if(this.filters.length == 0) {
      this.clearcosmosFilter()
    }else {
      this.searchBycosmosfilter()
    }
  }
  
  getcosmosCheckedValue(key) {
    let x  = this.filters.findIndex(f => f.name == key);
    return (x == -1) ? false : true;  
  }

getClass(key) {
 let x  =  this.filters.findIndex(f => f.type == 'filter_by_images' && f.name  == key);
 return (x != -1) ? true : false;
}

disabledFilter(key, type) {
  let x  = this.filters.findIndex(f => f.name == key);
  if(x != -1) {
    return false;
  } else {
    let x  = this.filters.findIndex(f => f.type == type);
      return (x == -1) ? false : true;  
  }
}

saveFiltersData() {
  localStorage.setItem('filtersData',JSON.stringify(this.filters));

}





}
