<main>
    <section class="tringle AraAbt secPad2 bg-light">
       <div class="container py-md-5 py-3">
          <div class="row align-items-center">
             <div class="col-lg-6 col-md-12">
                <div class="capAbt">
                   <!-- Section Tittle -->
                   <div class="large-title text-left">
                      <!-- <span>Service</span> -->
                      <h2 class="mb-3">Customer Service</h2>
                   </div>
                   <p class="text-left mb-1">
                     Quartz is the popular choice of every homeowner due to several reasons. Firstly, it is durable, and secondly, it can be customized based on residential and commercial settings. It is an engineered stone and is resistant to scratches and stains. In most cases, the warranty for quartz is up to 25 years. Surprisingly, it is durable and lasts forever.</p>
                   <p class="text-left mb-1">
                     Quartz is extremely resilient, and routine care is enough to keep its shine intact. With basic precautions, quartz can lend beauty to your home for years. Quartz can be cleaned with a mix of mild soap and water and doesn’t require sealing. It should not be used outdoors, as direct exposure to sunlight might damage it.</p>
                   <p class="text-left mb-1">
                     Also, installing quartz is not as easy as it looks. It requires trained professionals to fix quartz to space for proper finishing. Designing with quartz is easier as it looks exactly the way you have seen it online.</p>
                </div>
             </div>
             <div class="col-lg-6 col-md-12">
               <owl-carousel-o [options]="cutomeoption">
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s1.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>                
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s2.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>                
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                        <img src="assets/images/s2.jpg" class="m-auto w-auto img-fluid">
                        </span>
                     </div>                
                  </ng-template>
                  </owl-carousel-o>
             </div>
          </div>
       </div>
    </section>
    <section class="py-5" id="lcoation">
       <div class="container-fluid px-4">
          <div class="row justify-content-center">
             <div class="col-xl-10 px-sm-0 text-center">
                <div class="large-title">
                   <h2 class="mb-3">IGM Locations</h2>
                </div>
             </div>
          </div>
          <div class="row justify-content-center mt-3">
             <a *ngFor="let item of location"  routerLink="/{{item.city_slug}}" (click)="selectlocation('atlanta')" href="javascript:void(0)" class="IShine col-sm-4 col-xl overflow-hd px-1 mb-2 flex-grow-1">
                <div class="rounded clrBox color-1 owlC2">
                   <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                   <h4>{{item.city_name}}</h4>
                   <p>
                      {{item.address}}
                   </p>
                    <p> <a  href="tel:{{item?.phone}}">
                     <i class="fa fa-phone mb-1" aria-hidden="true"></i>  {{item.phone}}
                   </a> </p>
                   <p> 
                     <a href="mailto:{{item?.city_mail}}">
                     <i class="fa fa-envelope" aria-hidden="true"></i> {{item.city_mail}}</a>
                 </p>
                </div>
             </a>
          </div>
       </div>
    </section>
    <section id="contact" class="contact sBG iArea2 text-white">
       <div class="container-fluid">
          <div class="row">
             <div class="col-lg-12 d-flex align-items-stretch conFWrp flex-wrap">
                <div class="row">
                   <div class="d-flex align-items-center col-lg-6 p-md-5 p-3 bg-dark">
                      <div>
                         <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                            <div class="large-title text-left">
                               <h2 class="mb-3 text-white">Get in Touch!</h2>
                               <p class="text-left mb-1">Do you have any concerns, questions, or inquiries regarding any of our products or services offered in your location? Are you a designer, fabricator, or architect with some customized requirements, choices, or needs for your commercial or residential project? Do you or your client have any specific choice while searching for the perfect slab material for your space or setting?</p>
                               <p class="text-left mb-1"> 
                                  If any of these rings a bell, then please drop us a message by filling all the details in the form given below. And our team of experts would get back to you ASAP!  
                               </p>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-lg-6 p-md-5 p-3">
                      <form [formGroup]="contactForm" 
                      (ngSubmit)="onContactus(contactForm.value)" ngNativeValidate>
                      <div class="form-row">
                         <div class="col-md-6 form-group">
                            <label for="name">Your Name</label>
                            <input type="text" name="first_name" formControlName="first_name" class="text-white form-control bg-transparent" 
                               [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" id="first_name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                               <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                         </div>
                         <div class="col-md-6 form-group">
                            <label for="email">Your Email</label>
                            <input type="email" class="text-white form-control bg-transparent" name="email" formControlName="email" 
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" data-rule="email" data-msg="Please enter a valid email">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                               <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                         </div>
                      </div>
                      <div class="form-group">
                         <label for="subject">Subject</label>
                         <input type="text" class="text-white form-control bg-transparent" name="subject" formControlName="subject"
                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"  id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                         <div *ngIf="submitted && f.subject.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                            <div *ngIf="f.subject.errors.required">Subject is required</div>
                         </div>
                      </div>
                      <div class="form-group">
                         <label for="subject">Location</label>
                         <select class="text-white form-control bg-transparent" formControlName="location" 
                         [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                         <option *ngFor="let item of location" class="text-dark" value="{{item.city_name}}" class="text-dark">{{item.address}}</option>

                           <!-- <option value="Atlanta GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                           <option value="Houston TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                           <option  value="North Bergen NJ"class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                           <option  value="Quakertown PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                           <option value="Windsor CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                         </select>
                         <div *ngIf="submitted && f.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                            <div *ngIf="f.location.errors.required">Location is required</div>
                        </div>
                      </div>
                      <div class="form-group">
                         <label for="message">Message</label>
                         <textarea class="text-white form-control bg-transparent" name="message" rows="8" formControlName="message" 
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }" data-rule="required" data-msg="Please write something for us"></textarea>
                         <div *ngIf="submitted && f.message.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                            <div *ngIf="f.message.errors.required">Message is required</div>
                         </div>
                      </div>
                      <div class="text-center d-flex justify-content-center">
                         <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Send Message<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                      </div>
                      <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                         <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                            <div class="d-flex align-items-center">
                               <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> <p class="mb-0 mt-1 mr-3">Loading...</p>
                            </div>
                         </div>
                      </div>
                      <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                         <div class=" py-2 px-5 text-center">                            
                            <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                         </div>
                      </div>
                      </form>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section class="py-5 bg-white shadow-lg" id="care">
       <div class="container">
          <div class="row align-items-center justify-content-between">
             <div class="col-12 col-md-12 order-md-2 text-center">
                <div class="large-title">
                   <h2 class="mb-3">Care and Cleaning</h2>
                </div>
             </div>
          </div>
          <div class="row align-items-center justify-content-between">
             <div class="IShine col-12 col-md-12 col-lg-5 order-lg-2 text-right">
                <!-- Image -->
                <img src="{{tUrl}}/bgBox/1.jpg" alt="..." class="img-fluid w-lg-50 w-50">
                <!-- Image -->
                <div class="text-left mt-n13 mt-lg-n15 mb-10 mb-md-0">
                   <img src="{{tUrl}}/bgBox/4.jpg" alt="..." class="img-fluid w-lg-75 w-50">
                </div>
             </div>
             <div class="col-12 col-md-12 col-lg-7 order-lg-1 mt-lg-0 mt-md-4 mb-4">
                <p class="mb-2">
                  Quartz is the popular choice of every homeowner due to several reasons. Firstly, it is durable, and secondly, it can be customized based on residential and commercial settings. It is an engineered stone and is resistant to scratches and stains. In most cases, the warranty for quartz is up to 25 years. Surprisingly, it is durable and lasts forever.</p>
                <p class="m-0">
                  Quartz is extremely resilient, and routine care is enough to keep its shine intact. With basic precautions, quartz can lend beauty to your home for years. Quartz can be cleaned with a mix of mild soap and water and doesn’t require sealing. It should not be used outdoors, as direct exposure to sunlight might damage it.</p>
                <p class="m-0">
                  Also, installing quartz is not as easy as it looks. It requires trained professionals to fix quartz to space for proper finishing. Designing with quartz is easier as it looks exactly the way you have seen it online.</p>
             </div>
          </div>
          <div class="row">
             <div class="col-xl-6 mb-3">
                <div class="h-100 card mb-3 shadow-sm border-0">
                   <div class="card-header border-0 p-0">
                      <h6 class="mb-0 P-4">
                         <div class="p-3 text-left text-dark w-100">How Cleaning Should be Done</div>
                      </h6>
                   </div>
                   <div class="collapse show">
                      <div class="card-body pb-0 pt-2">
                         <p class="mb-0">Cleaning stone surfaces with mild liquid dishwashing detergent and water keep the stone’s beauty intact for a long time. It is recommended to use a clean rag mop for the best results. To remove soap scum, use a solution of ammonia and water.</p>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-xl-6 mb-3">
                <div class="h-100 card mb-3 shadow-sm border-0">
                   <div class="card-header border-0 p-0">
                      <h6 class="mb-0 P-4">
                         <div class="p-3 text-left text-dark w-100"> Cleaning Products</div>
                      </h6>
                   </div>
                   <div class="collapse show">
                      <div class="card-body pb-0 pt-2">
                         <p class="mb-0">Products containing vinegar or lemon might dull the stone’s beauty. Make sure that you do not mix bleach and ammonia, as this creates harmful gases. Scouring creams contain abrasives, which might scratch stones.
                         </p>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-xl-6 mb-3">
                <div class="h-100 card mb-3 shadow-sm border-0">
                   <div class="card-header border-0 p-0">
                      <h6 class="mb-0 P-4">
                         <div class="p-3 text-left text-dark w-100">Sealing the Stone
                         </div>
                      </h6>
                   </div>
                   <div class="collapse show">
                      <div class="card-body pb-0 pt-2">
                         <p class="mb-0">Sealing products are impregnators, which do not act as a sealant but more like a repellant. Sealing makes the stone more resistant to stain. Talk with the experts about sealing procedures, and you might know the best sealing practices. Consult with the specific sealing manufacturer and the frequency to use.
                         </p>
                      </div>
                   </div>
                </div>
             </div>
             <div class="col-xl-6 mb-3">
                <div class="h-100 card mb-3 shadow-sm border-0">
                   <div class="card-header border-0 p-0">
                      <h6 class="mb-0 P-4">
                         <div class="p-3 text-left text-dark w-100">Finding Stains and Removing Them
                         </div>
                      </h6>
                   </div>
                   <div class="collapse show">
                      <div class="card-body pb-0 pt-2">
                         <p class="mb-0">After identifying stains, it becomes easier to remove them. Stains can be acidic, metallic, or paint based. If you are still wondering what caused the stain, then do check out for staining agents that are present. Consider asking some questions like - what is its shape or what color is it.
                         </p>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <section class="py-5 bg-light" id="faq">
       <div class="container">
          <div class="row mb-3 align-items-center justify-content-center">
             <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                <div class="large-title">
                   <h2 class="mb-3">FAQ</h2>
                   <p>If you are thinking of installing quartz for any of your residential or commercial projects, we have compiled a list of questions that we frequently receive. These are the most common questions and answers about quartz </p>
                </div>
             </div>
          </div>
          <div class="row mb-3 align-items-center justify-content-center">
             <div class="col-xl-10 col-md-12 px-sm-0">
                <div id="accordion">
                   <div class="card mb-3 shadow-sm border-0">
                      <div class="card-header border-0 p-0" id="headingOne">
                         <h5 class="mb-0">
                            <button class="p-3 text-left text-dark btn btn-link w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Q. Is quartz a natural stone? </button>
                         </h5>
                      </div>
                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" style="">
                         <div class="card-body">
                            <p>Quartz is made up of 92-95% ground quartz and is combined with resin and pigments. It is one of the hardest materials and is scratch resistant. It is not natural and is fabricated like marble and granite from large slabs.</p>
                       
                         </div>
                      </div>
                   </div>
                   <div class="card mb-3 shadow-sm border-0">
                      <div class="card-header border-0 p-0" id="headingTwo">
                         <h5 class="mb-0">
                            <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Q.  What is the durability of quartz? </button>
                         </h5>
                      </div>
                      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion" style="">
                         <div class="card-body">
                            <p>Quartz is the most durable engineered stone. It is resistant to chips, stains, and cracks. However, it is recommended to use chopping boards when cutting anything. Quartz is a mixture of 93% quartz and 7% resin that makes it stronger and beautiful. The excessive pressure from objects can harm the quartz surface </p>
                         </div>
                      </div>
                   </div>
                   <div class="card mb-3 shadow-sm border-0">
                      <div class="card-header border-0 p-0" id="headingThree">
                         <h5 class="mb-0">
                            <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Q. How can you care for quartz countertops? </button>
                         </h5>
                      </div>
                      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion" style="">
                         <div class="card-body">
                            <p>Unlike other stones, quartz doesn’t need sealing and is easy to clean. It is naturally scratch-resistant and is resistant to wine, coffee, and lemon juice. For routine cleaning, use water and mild soap and a small amount of non-abrasive cleaner to remove stains. </p>
                         </div>
                      </div>
                   </div>
                   <div class="card mb-3 shadow-sm border-0">
                      <div class="card-header border-0 p-0" id="headingFour">
                         <h5 class="mb-0">
                            <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Q. What are the applications of quartz?  </button>
                         </h5>
                      </div>
                      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion" style="">
                         <div class="card-body">
                            <p>Quartz has multiple applications. It can be used in bathrooms, fireplaces, kitchens, tabletops, shower surrounds, waiting areas, and more. For commercial projects, it can be used for reception tops, conference tables, and more. </p>
                         </div>
                      </div>
                   </div>
                   <div class="card mb-3 shadow-sm border-0">
                      <div class="card-header border-0 p-0" id="headingFive">
                         <h5 class="mb-0">
                            <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Q. Is quartz resistant to heat? </button>
                         </h5>
                      </div>
                      <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion" style="">
                         <div class="card-body">
                            <p>Quartz is heat resistant, but direct exposure to heat can damage it. It might discolor the resin. Therefore, it is recommended to put a hot pad for any hot items when placing them on the countertop. </p>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>
 
 