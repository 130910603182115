import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SharedService, SeoServiceService} from '../service/index';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  tUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tUrl2 = 'https://cosmosgranite.nyc3.digitaloceanspaces.com/coalescequartz/images';
  cutomeoption : OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    smartSpeed: 450,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  cutomeoption2 : OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    smartSpeed: 450,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  constructor(private api: ApiService, private shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService: SeoServiceService,) { 
      this.activatedRoute.fragment.subscribe( fragment =>{
        let items = ['Fabricators', 'Kitchen', 'Designer', 'Architect'];
        let x  = items.findIndex(f => f == fragment);
        if(x != -1) {
          setTimeout(() => {
            
            document.getElementById(fragment).click();
          }, 500);
        }
      })

      this._seoService.updateTitle('Leading Provider of Quartz Products for Any Project | Coalesce Quartz');
      this._seoService.updateDescription('At Coalesce Quartz, we are committed to delivering premium quartz products for any project needs. Come visit our store & we would be happy to assist you.')
    // this.shared.cleardata();

    // this.getCategory();
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
 
  }

  // getCategory() {
  //   this.api.fetchData('product_category',{},'GET').subscribe(
  //     res => {
  //       if(res['status'] == 1) {
  //         this.shared.categoryList = res['category']
  //       }
  //     }
  //   )
  // }


}
