<main>
   <section class="py-5 bg-light locBdyS">
      <div class="container px-4">
         <div class="row justify-content-center">
            <div class="col-xl-10 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">WHERE TO BUY</h2>
               </div>
            </div>
         </div>
         <div class="row justify-content-center mt-3">
            <div class="col-lg-4 col-md-6 text-center" *ngFor="let item of location; let i = index">
               <div class="locGBox atlanta-grid mb-4">
                  <a style="background-image: url('{{item.image}}')" href="javascript:void(0)" class="IShine col-12 overflow-hd px-1 mb-2 flex-grow-1 cursor-none">
                     <div class="addBox addBox2">
                        <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                        <h4>{{item.city_name}}</h4>
                        <p>
                           {{item.address}}
                        </p>
                        <p> 
                        <a href="tel:{{item?.phone}}" >
                           <i class="fa fa-phone  mb-1" aria-hidden="true"></i>  {{item.phone}}
                        </a>
                     </p>
                  <p> 
                     <a href="mailto:{{item?.city_mail}}" >
                        <i class="fa fa-envelope" aria-hidden="true"></i> {{item.city_mail}}
                     </a>
                  </p>
                  </div>
                  </a>
               </div>
            </div>
            <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                  <div class="d-flex align-items-center">
                     <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
                     <p class="mb-0 mt-1 mr-3">Loading...</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>