import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SharedService } from '../service';
import { searchForm } from '../forms'

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  tUrl = 'https://cosmosgranite.nyc3.digitaloceanspaces.com/coalescequartz/images';
  searchForm = searchForm;
  hovDID;
  defStyle= {display: 'none'};
  hovStyle= {display: 'block'};
  cosmosFilter;
  submitted = false;
  searchedPro;
  currenturl;
  constructor(private fb: FormBuilder, private api: ApiService, public shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router) { 
      this.currenturl = window.location.pathname;
      // console.log(this.currenturl);
      this.searchForm = this.fb.group({
        search: [null, ''],
      });  
      this.searchForm.patchValue({
        search:null
      })
    this.getAllQuartzFilter()

  }

  ngOnInit(): void {
  }

  onSearch(e) {
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
    this.searchedPro = undefined;
    let value = e.target.value.trim();

      if(value.length >2) {
        let formData: FormData = new FormData(); 

        formData.append('name', value ); 
        this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
        res => {
          if(res['status'] == 1) {
            this.searchedPro = res['product']
          } else {
            this.searchedPro = []
          }
        }
      )
       }
       if(value.length == 0) {
        this.searchedPro = []
       }
  }

  onSubmit(data) {
    // console.log(data)
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
    this.searchedPro = undefined;
    let value =data.search.trim();

      if(value.length >2) {
        let formData: FormData = new FormData(); 
        formData.append('name', value ); 
        this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
        res => {
          if(res['status'] == 1) {
            this.searchedPro = res['product']
            this.shared.searchedKey = value;
            this.router.navigate(['quartz'])
            this.closeModel();

          } else {
            this.shared.searchedKey = value;
            this.router.navigate(['quartz'])
            this.searchedPro = []
            this.closeModel();

          }
        }
      )
       }
       if(value.length == 0) {
        this.searchedPro = []
       }
  }

  clearSearch() {
    this.searchedPro = undefined;
    this.searchForm.patchValue({
      search:null
    })
  }


  ngAfterViewInit() {
    $(document).ready(function() {
      $('#list').click(function(event){
        event.preventDefault();
        $('#products .item').removeClass('col-lg-4').removeClass('px-5');
        $('#products .item').addClass('col-lg-12').addClass('px-0');
        $('#products .item .listview').addClass('d-flex');
        $('#products .item .thumb').addClass('d-none');
      });
      $('#grid').click(function(event){
        event.preventDefault();
        $('#products .item').removeClass('col-lg-12').addClass('px-5');
        $('#products .item').addClass('col-lg-4').removeClass('px-0');
        $('#products .item .listview').addClass('d-none');
        $('#products .item .listview').removeClass('d-flex');
        $('#products .item .thumb').removeClass('d-none');
    
      });

      // if ($(window).width() <= 767) {
      //   $('.hedDes li a').click(function () {
      //     // $(".navbar-collapse").collapse('hide');
      //     console.log('click')
      //     $('#collapse-btn-nav').click();
      //     // $(".dLarge").hide('add');
      //     // $(this).find(".dropdown-menu").css('display', 'none');
      //   });
      //   $('.hedDes li dropdown-menu li a').click(function () {
      //     // $(".navbar-collapse").collapse('hide');
      //     console.log('click')
      //     $('#collapse-btn-nav').click();
      //     // $(".dLarge").hide('add');
      //     // $(this).find(".dropdown-menu").css('display', 'none');
      //   });
     
      // }
    
    });
  }

  onClickColor(color) {
    localStorage.setItem('color',color)
    if(window.location.pathname == '/quartz') {
      // console.log('runnn')
      this.shared.changeFilter =  true;
    }
  }

  showWHvr(divId: number) {
    if(window && window.innerWidth >= 991 ) {
      this.hovDID = divId;
    }
  }

  sAllWhDS() {
    if(window && window.innerWidth >= 991 ) {
    this.hovDID = null; 
    }
  }

  getAllQuartzFilter(){
    // https://staging.cosmosgranite.com/api/getAllFilter
    this.api.fetchDataCosmos('getAllFilter',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
       this.cosmosFilter = res['filter']['color']
        } else {
       
        }
      }
    )
  }

  openSearchForm() {
    this.clearSearch();
    setTimeout(()=> {
      document.getElementById('search_input').click();
      document.getElementById('search_input').focus();

    }, 500)
  }
  closeModel(){
    document.getElementById('dismis-model').click();
    this.clearSearch()
  }

  mClick(id) {
    console.log(this.hovDID);
    console.log('id', id);
    if(this.hovDID == id) {
      this.hovDID = '';
      // $(".navbar-collapse").collapse('hide');
    } else {
      this.hovDID = id;
    }

  }

  chaneOfRout() {
    if(window && window.innerWidth <= 991 ) {
      console.log('runn route')
      let element = document.getElementById("collapse-btn-nav") 
      if(element != null) {
        element.click();
      }
    }
  }




}
