<main>
   <section class="headInnP">
      <div class="container py-md-5 pt-3 pb-4">
      </div>
   </section>
   <section class="iArea quatslisting pb-5" style="background-image: url('{{tUrl}}/bg-location.jpg')">
      <div class="container py-md-5 pt-3 pb-4">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="capAbt">
                  <!-- Section Tittle -->
                  <div class="large-title text-center">
                     <!-- <span>Service</span> -->
                     <h2 class="mb-0"> COALESCE QUARTZ</h2>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center mt-2 mx-0 px-3">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb px-3 bg-white mb-0">
                     <li class="breadcrumb-item"><a [routerLink]="['/']" href="javascript:void(0)" class="text-dark"><i class="fa fa-home"></i></a></li>
                     <li class="breadcrumb-item"><a [routerLink]="['/quartz']" href="javascript:void(0)" class="text-dark">COALESCE QUARTZ</a></li>
                  </ol>
               </nav>
            </div>
         </div>
      </div>
   </section>
   <div class="search-Row">
      <div class="row justify-content-center align-items-center flex-wrap flex-sm-nowrap">
         <div class="col-md-8">
            <div class="w-100 SrhCon">
               <form [formGroup]="searchForm" 
               (ngSubmit)="onSearch(searchForm.value)" ngNativeValidate>
               <div class="finder">
                  <div class="fOuter">
                     <div class="fInner">
                        <input class="fInput" formControlName="search" (keyup)="onSearch($event)" type="text" name="q" placeholder="Search...">
                        <div *ngIf="!searchedPro || !flag" class="fIcon" ref="icon"></div>
                        <div *ngIf="searchedPro  && flag == 'search'" (click)="clearSearch()">
                        <i class="fa fa-times fa-2x"></i>
                     </div>
                  </div>
               </div>
            </div>
            </form>
         </div>
      </div>
      <div class="mt-3 mt-sm-0 col-auto d-flex align-items-center flex-wrap flex-sm-nowrap">
         <div class="fBtn text-center">
            <div *ngIf="!showFilter">
               <button type="button" class="shadow-lg btn btn-dark" (click)="showFilter = true; scrollTO()"><i class="fa fa-filter"></i></button>
               Filter
            </div>
            <div *ngIf="showFilter" class="primary">
               <button type="button" class="shadow-lg btn btn-primary" (click)="showFilter = false"><i class="fa fa-filter"></i></button>
               Filter
            </div>
         </div>
         <div class="ml-4">
            <div class="pull-right">
               <div class="btn-group">
                  <button id="grid" class="btn btn-outline-dark" (click)="view = ''" [ngClass]="{'active':view == ''}"><i aria-hidden="true" class="fa fa-th"></i></button>
                  <button id="list" class="btn btn-outline-dark" (click)="view = 'list'" [ngClass]="{'active':view == 'list'}"><i aria-hidden="true" class="fa fa-th-list"></i></button>
               </div>
            </div>
         </div>
      </div>
      <!--end of col-->
   </div>
   </div>
   <section class="pt-3 pt-sm-5 pb-5">
      <div class="container-fluid">
         <div class="row">
            <div class="col-md-5 order-md-1 col-lg-3 sidebar-filter" *ngIf="showFilter"></div>
            <div class="col-md-7 order-md-2 col-lg-9" [ngClass]="{'col-md-12 col-lg-12 ':!showFilter}">
               <div class="row mb-3 align-items-center justify-content-center">
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5 col-lg-4 order-md-1 col-xl-3 sidebar-filter" *ngIf="showFilter">
               <div id="accordion" class="p-3 border" *ngIf="cosmosFilter">
                  <div class="row d-xl-none">
                     <div class="col-lg-12">
                        <ng-container *ngFor="let item of filters; let i = index">
                           <div class="chip ml-1">
                              {{item.name}} 
                              <i class="close fa fa-times" (click)="removecosmosFilter(i)"></i>
                           </div>
                        </ng-container>
                     </div>
                  </div>
                  <div class="card mb-2 border-0 bg-transparent">
                     <div class="card-header border-0 p-0 bg-transparent border-0">
                        <h5 class="mb-0">
                           <button aria-controls="collapseTwo" aria-expanded="true" id="headingTwo" data-target="#collapseTwo" data-toggle="collapse" class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed">Colors</button>
                        </h5>
                     </div>
                     <div id="collapseTwo" class="collapse" data-parent="#accordion" style="">
                        <div class="card-body border-0 p-0">
                           <ul class="list-group">
                              <li class="list-group-item border-0 px-md-3 p-0">
                                 <div class="chip" *ngFor="let item of cosmosFilter['color']" (click)="selecCosmosFliter(item,'color')"
                                 [ngClass]="{ 'chip-disable': disabledFilter(item.color, 'color') }">{{item.color}}</div>
                              </li>
                           </ul>
                        </div>
                  </div>
               </div>
               <div class="card mb-2 border-0 bg-transparent">
                  <div class="card-header border-0 p-0 bg-transparent border-0">
                     <button
                        class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed"
                        data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Filter by Images
                     </button>
                  </div>
                  <div id="collapseFour" class="collapse" data-parent="#accordion" style="">
                     <div class="card-body py-0 px-0 px-md-4 border-0">
                        <ul class="list-group border-0 pl-1">
                           <li class="list-group-item px-0 border-0 position-relative pl-3 py-1"
                              *ngFor="let item of cosmosFilter['filter_by_images']; let i = index">
                              <input id="{{item}}_{{i}}" class="form-check-input" type="checkbox" value="" (change)="checkcosmosFilter($event, item, 'filter_by_images')" [checked]="getcosmosCheckedValue(item)"
                              >
                              <label class="form-check-label" for="{{item}}_{{i}}">
                              {{item}}
                              </label>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="card mb-2 border-0 bg-transparent">
                  <div class="card-header border-0 p-0 bg-transparent border-0">
                     <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Thickness</button>
                  </div>
                  <div id="collapseFive" class="collapse" data-parent="#accordion" style="">
                     <div class="card-body py-0 px-0 px-md-4">
                        <ul class="list-group">
                           <li class="list-group-item border-0 px-0">
                              <div class="chip" *ngFor="let item of cosmosFilter['thickness']" (click)="selecCosmosFliter(item,'thickness')"
                              >{{item}}</div>
                           </li>
                        </ul>
                  </div>
               </div>
            </div>
            <div class="card mb-2 border-0 bg-transparent">
               <div class="card-header border-0 p-0 bg-transparent border-0">
                  <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Usages</button>
               </div>
               <div id="collapseSeven" class="collapse" data-parent="#accordion" style="">
                  <div class="card-body  px-0 px-md-4 pt-2 border-0">
                     <ul class="list-group border-0 pl-1">
                        <li class="list-group-item px-0 border-0 position-relative pl-3 py-1" *ngFor="let item of cosmosFilter['usage']; let i  = index">
                           <input class="form-check-input" type="checkbox" value="" (change)="checkcosmosFilter($event, item, 'usage')" id="{{item}}_{{i}}" [checked]="getcosmosCheckedValue(item)">
                           <label class="form-check-label" for="{{item}}_{{i}}">{{item}}</label>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="card mb-2 border-0 bg-transparent">
               <div class="card-header border-0 p-0 bg-transparent border-0">
                  <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false"aria-controls="collapseFive">Series</button>
               </div>
               <div id="collapseNine" class="collapse" data-parent="#accordion" style="">
                  <div class="card-body py-0 px-0 px-md-4">
                     <ul class="list-group">
                        <li class="list-group-item border-0 px-0">
                           <div class="chip" *ngFor="let item of cosmosFilter['Series']"
                           (click)="selecCosmosFliter(item,'Series')"
                           [ngClass]="{ 'chip-disable': disabledFilter(item, 'Series') }">{{item}}</div>
                        </li>
                     </ul>
               </div>
            </div>
         </div>
         <div class="col-md-12 text-center d-flex">
            <button class="btn btn-dark" (click)="clearcosmosFilter()" [disabled]="filters.length == 0">Reset Filter</button>
            <button class="btn btn-dark mb-btn" (click)="showFilter = false">Apply </button>
         </div>
      </div>
      </div>
      <div class="col-md-7 col-lg-8 order-md-2 col-xl-9" [ngClass]="{'col-md-12 col-lg-12 col-xl-12':!showFilter}">
         <div class="grid-repeat">
            <div class="row">
               <div class="col-lg-12">
                  <ng-container *ngFor="let item of filters; let i = index">
                     <div class="chip ml-1">
                        {{item.name}} 
                        <i class="close fa fa-times" (click)="removecosmosFilter(i)"></i>
                     </div>
                  </ng-container>
               </div>
            </div>
            <ng-container  *ngIf="view == 'list'">
               <div class="d-flex row productRow mt-2 pl-4" infiniteScroll
               [infiniteScrollDistance]="8"
               [infiniteScrollThrottle]="50"
               (scrolled)="onScrollQuarts()" *ngIf="quartsData">
               <div class="row listviewBox">
                  <div class="col-md-12" *ngFor="let item of quartsData; let  i= index">
                     <a href="javascript:void(0)" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                        (click)="saveFiltersData()" class="listview w-100 flex-wrap d-flex">
                        <div class="mx-0 row align-items-center justify-content-center col-12">
                           <div  *ngFor="let data of item['images']; let  i= index" class="shadow-lg hoverList div1" 
                              style="background-image: url({{data.image}});">
                           </div>
                        </div>
                        <div class="mt-2 mb-4 col-12 text-dark text-center mt-3"> {{item.ProductName}}</div>
                        <div  *ngIf="quartsData && quartsData.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                           <div class=" bg-light py-2 px-5 text-center">
                              <p class="mb-0 mt-1"> No product found.</p>
                           </div>
                        </div>
                     </a>
                  </div>
               </div>
         </div>
         </ng-container>
         <ng-container *ngIf="view == 'list' && searchedPro ">
            <div class="row listviewBox">
               <div class="col-md-12" *ngFor="let item of searchedPro; let  i= index">
                  <a href="javascript:void(0)" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                     (click)="saveFiltersData()" class="listview w-100 flex-wrap d-flex">
                     <div class="mx-0 row align-items-center justify-content-center col-12">
                        <div  *ngFor="let data of item['images']; let  i= index" class="shadow-lg hoverList div1" 
                           style="background-image: url({{data.image}});">
                        </div>
                     </div>
                     <div class="mt-2 mb-4 col-12 text-dark text-center mt-3"> {{item.ProductName}}</div>
                  </a>
               </div>
               <div  *ngIf=" searchedPro.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" bg-light py-2 px-5 text-center">
                     <p class="mb-0 mt-1"> No product found.</p>
                  </div>
               </div>
            </div>
         </ng-container>
         <ng-container *ngIf="view != 'list' && searchedPro && getClass('Book Match') ">
            <div class="row productRow mt-2 px-md-2 mirror-box">
               <div class="text-center IShine col-md-12 col-lg-12 col-xl-12 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                  *ngFor="let item of searchedPro; let  i= index">
                  <a routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                     (click)="saveFiltersData()" href="javascript:void(0)" class="d-block text-center">
                  <img *ngIf="item.thumbnail" src="{{item.thumbnail}}" class="img-fluid" />
                  <img *ngIf="!item.thumbnail" src="{{tUrl}}/placeholder.jpg" class="img-fluid" />
                  </a>
                  <div *ngIf="item.thumbnail" class="pDetails coalDetail coalDetail2 m_proDetail">
                     {{item.ProductName}}
                  </div>
                  <div *ngIf="!item.thumbnail" class="pDetails coalDetail coalDetail2 m_proDetail">
                     {{item.ProductName}}
                  </div>
               </div>
               <div  *ngIf="searchedPro.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" bg-light py-2 px-5 text-center">
                     <p class="mb-0 mt-1"> No product found.</p>
                  </div>
               </div>
            </div>
         </ng-container>
         <div class="d-flex row productRow mt-2 px-md-2 flatImage" 
            *ngIf="view != 'list' && searchedPro && (getClass('Installation picture') || getClass('Full Slab')) ">
            <div class="text-center col-md-12 col-lg-6 col-xl-6 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
               *ngFor="let item of searchedPro; let  i= index" 
               >
               <div class="thumbflat">
                  <a *ngIf="item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                  (click)="saveFiltersData()" class="flatImage bg-light" href="javascript:void(0)" style="background-image: url('');">
                  <img src="{{item.thumbnail}}" class="fixed-layout" />
                  </a>
                  <a *ngIf="!item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                  (click)="saveFiltersData()" class="img-placeholder flatImage bg-light" style="background-image: url('');" href="javascript:void(0)" 
                  >
                  <img src="{{tUrl}}/placeholder-rec.jpg" class="img-fluid" />
                  </a>
               </div>
               <div *ngIf="item.thumbnail" class="pDetails coalDetail coalDetail2 m_proDetail">
                  {{item.ProductName}}
               </div>
               <div *ngIf="!item.thumbnail" class="pDetails coalDetail coalDetail2 m_proDetail">
                  {{item.ProductName}}
               </div>
            </div>
            <div class="row justify-content-center pt-4 mx-0">
            </div>
            <div  *ngIf="searchedPro.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class=" bg-light py-2 px-5 text-center">
                  <p class="mb-0 mt-1"> No product found.</p>
               </div>
            </div>
            <hr class="my-5">
         </div>
         <div class="d-flex row productRow mt-2 pl-4" *ngIf=" view != 'list' && searchedPro && getClass('dummy') == false &&  getClass('Book Match') ==false && getClass('Installation picture') == false && getClass('Full Slab')  == false ">
            <div class="IShine col-md-6 col-lg-4 col-xl-3 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
               *ngFor="let item of searchedPro; let  i= index" 
               >
               <div class="thumb">
                  <a *ngIf="item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                  (click)="saveFiltersData()" href="javascript:void(0)" style="background-image: url({{item.thumbnail}});"></a>
                  <a *ngIf="!item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" 
                  (click)="saveFiltersData()" class="img-placeholder" href="javascript:void(0)" style="background-image: url({{tUrl}}/placeholder.jpg);">
                  </a>
               </div>
               <div *ngIf="item.thumbnail" class="pDetails coalDetail">
                  {{item.ProductName}}
               </div>
               <div *ngIf="!item.thumbnail" class="pDetails coalDetail">
                  {{item.ProductName}}
               </div>
            </div>
            <div class="row justify-content-center pt-4 mx-0">
            </div>
            <div  *ngIf="searchedPro.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class=" bg-light py-2 px-5 text-center">
                  <p class="mb-0 mt-1"> No product found.</p>
               </div>
            </div>
            <hr class="my-5">
         </div>
         <ng-container *ngIf="view != 'list'">
            <div class="d-flex row productRow mt-2 pl-4" infiniteScroll
            [infiniteScrollDistance]="8"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrollQuarts()" *ngIf="quartsData">
            <div class="IShine col-md-6 col-lg-4 col-xl-3 overflow-hd px-1 mb-5 flex-grow-1"
               *ngFor="let item of quartsData; let  i= index" 
               >
               <div class="thumb" routerLink="/{{item.category_slug}}/{{item.productSlug}}" (click)="saveFiltersData()">
                  <a *ngIf="item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" (click)="saveFiltersData()" href="javascript:void(0)" style="background-image: url('{{item.thumbnail}}');"></a>
                  <a *ngIf="!item.thumbnail" routerLink="/{{item.category_slug}}/{{item.productSlug}}" (click)="saveFiltersData()" class="img-placeholder" href="javascript:void(0)" style="background-image: url({{tUrl}}/placeholder.jpg);">
                  </a>
               </div>
               <div class="pDetails coalDetail">
                  {{item.ProductName}}
               </div>
            </div>
            <div class="row justify-content-center pt-4 mx-0" *ngIf="quartsData.length">
            </div>
            <hr class="my-5">
            <div  *ngIf="quartsData && quartsData.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class=" bg-light py-2 px-5 text-center">
                  <p class="mb-0 mt-1"> No product found.</p>
               </div>
            </div>
      </div>
      </ng-container>
      <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
         <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
            <div class="d-flex align-items-center">
               <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
               <p class="mb-0 mt-1 mr-3">Loading...</p>
            </div>
         </div>
      </div>
      </div>
      </div>
      </div>
      </div>
   </section>
</main>