import { Component, OnInit } from '@angular/core';
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  location = [];
  loadMore
  constructor(private api: ApiService,private router: Router, private shared: SharedService,  private _seoService: SeoServiceService,) { 
    this.getLocation();
    this.loadMore =  true;
    // this.getCategory();
    this._seoService.updateTitle('Where to Buy Quartz Products | Our Different Warehouse Locations | Coalesce Quartz');
    this._seoService.updateDescription('We offer an extensive range of quartz at different locations in the US. Pay a visit to the nearby store and select the best product for your home or office.')
  }

  ngOnInit(): void {
  }
  
  selectlocation(value){
    localStorage.setItem('location',value);
    this.router.navigate(['/'+value]);

  }

  getLocation() {
    this.loadMore =  true;
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
          this.loadMore =  false;
        } else {
          this.location =  [];
          this.loadMore =  false;
        }
      }
    )
  }

  // getCategory() {
  //   this.api.fetchData('product_category',{},'GET').subscribe(
  //     res => {
  //       if(res['status'] == 1) {
  //         // this.shared.categoryList = res['category']
  //       }
  //     }
  //   )
  // }



}
