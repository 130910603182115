<ng-container>
   <section class="banPrt">
      <div class="container-fluid p-0 m-0">
         <div class="">
            <owl-carousel-o  [options]="customOptions" #heroSlider> 
            <ng-template class="hero-slider" carouselSlide  *ngFor="let itemdata of sliderData; let i = index"
            [id]="itemdata.image">
            <div class="item" style="background-image: url({{itemdata.background_image}})">
               <div class="owl-content shadow-lg">
                  <div class="row align-items-center mx-0">
                     <div class="col-lg-8">
                        <div class="img-border position-relative">
                           <img  [src]="itemdata.image" class="img-fluid shadow-lg">
                        </div>
                     </div>
                     <div class="col-lg-4">
                        <div class="conSldSec">
                           <h3 class="">{{itemdata.text}}<span class="next_line_txt"> </span></h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            </ng-template>           
            </owl-carousel-o>         
         </div>
      </div>
   </section>
   <section *ngIf="loadmore" class="pt-3 mt-3">
      <div class="col-12 pt-5 mt-5">
         <div  role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
            <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
               <div class="d-flex align-items-center">
                  <img src="https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images/loader.svg" class="img-fluid" width="50" /> 
                  <p class="mb-0 mt-1 mr-3">Loading...</p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="bg-white pt-5">
      <div class="container">
         <div class="row justify-content-center">
            <div class="large-title mb-1">
               <div class="SecHead">
                  <span class="second_line"></span>
                  <h2 class="fontLobster">Our Products</h2>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-12 d-flex flex-wrap">
               <div class="col-lg-4 col-md-6 col-12 mb-5 overflow-hd px-4"  *ngFor="let item of quartsData; let i = index">
                  <div class="thumb">
                     <a routerLink="/{{item.category_slug}}/{{item.productSlug}}" style="background-image:url({{item.thumbnail}});">
                     </a>
                     <div class="pDetails">
                        {{item.ProductName}}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-2 mb-5 mt-5">
            <div class="col-lg-12 text-center">
               <div>
                  <a routerLink="quartz" class="text-white btn-theme shadow-lg btn btn-lg">
                     <span class="d-flex align-items-center">
                        view more
                        <i class="fW900 border-0 text-uppercase ml-1 fa fa-long-arrow-right" aria-hidden="true"></i>
                     </span>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="iArea py-5">
      <div class="container py-5">
         <div class="text-center">
            <span class="icon-animation">
               <span class="icon_inner_blue">
                  <i class="fa fa-info faa-bounce animated"></i>
               </span>               
            </span>
            <h2 class="mb-4">WE'RE HERE TO HELP!</h2>
            <button class="text-white fW900 btn mb-2 border-0 text-uppercase" routerLink="/contact">get in touch<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
         </div>
      </div>
   </section>
   <section class="bg-white pt-5 py-3">
      <div class="container-fluid px-5">
         <div class="row justify-content-center">
            <div class="large-title mb-1">
               <div class="SecHead">
                  <span class="second_line"></span>
                  <h2 class="fontLobster">Where To Buy</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid px-5">
         <div class="row justify-content-center mt-3">
            <span  *ngFor="let item of location" class="IShine col-sm-4 col-xl-4 overflow-hd  mb-sm-5 mb-0 flex-grow-1">
               <div class=" clrBox color-13 owlC2">
                  <i class="fa fa-map-marker fa-2x mb-2" aria-hidden="true"></i>
                  <h4>{{item.city_name}}</h4>
                  <p>
                     {{item.address}}
                  </p>
                  <p>
                     <a href="tel:{{item?.phone}}">
                        <i class="fa fa-phone mb-1" aria-hidden="true"></i>&nbsp;{{item.phone}}
                     </a> 
                  </p>
                  <p> 
                     <a href="mailto:{{item?.city_mail}}">
                        <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;{{item.city_mail}}
                     </a>
                  </p>
               </div>
            </span>
         </div>
      </div>
   </section>
</ng-container>