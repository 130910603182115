import { FormControl, FormGroup } from '@angular/forms';


export const loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
export const contactForm = new FormGroup({
    first_name: new FormControl(''),
    email: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
    location: new FormControl(''),
  });
  
export const searchForm = new FormGroup({
    search: new FormControl(''),
  
  });
export const helpForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    street: new FormControl(''),
    product: new FormControl(''),
    area: new FormControl(''),
    message: new FormControl(''),
  });
export const helpForm2 = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    street: new FormControl(''),
    area: new FormControl(''),
    message: new FormControl(''),
    location: new FormControl(''),

  });
export const orderForm = new FormGroup({
    fname: new FormControl(''),
    lname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    company_name: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    zip: new FormControl(''),
    location: new FormControl(''),
    occupation: new FormControl(''),
    address: new FormControl(''),
  });
export const uoloadImage = new FormGroup({
  image_option: new FormControl(''),
  image_title: new FormControl(''),
  product_name: new FormControl(''),
  website_name: new FormControl(''),
  cat_id: new FormControl(''),
  cat_name: new FormControl(''),
  job_name: new FormControl(''),
  customer_name: new FormControl(''),
  image: new FormControl(''),
  image_thumb: new FormControl(''),
  product_id: new FormControl(''),
  captcha: new FormControl(''),
  location: new FormControl(''),
  imagedata: new FormControl(''),

  });

  export const apponitmentForm = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    city: new FormControl(''),
    message: new FormControl(''),
    location: new FormControl(''),
    date_time: new FormControl(''),
    time: new FormControl(''),
  });




