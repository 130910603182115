import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	tUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
	tUrl2 = 'https://cosmosgranite.nyc3.digitaloceanspaces.com/coalescequartz/images';
	year = new Date().getFullYear(); 
  address:any ;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
    this.getAddress()
  }

  getAddress(){
    this.api.fetchData('address',{},'GET').subscribe(res => {
      console.log(res); 
      if(res['status'] == 1) {
        if(res['data'].length > 0){
          this.address = res['data'][0]['address'];

        }else {
          this.address = 'N/A';

        }
      }else {
        this.address = 'N/A';
      }
    })
  }

}
