<section class="pLogo">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-12 text-center">
            <picture>
               <source media="(max-width: 767px)" srcset="{{tUrl}}/partner/m-multi-partner.png">
               <img alt="partner" class="img-fluid" src="{{tUrl}}/partner/multi-partner.png">
            </picture>
         </div>
      </div>
   </div>
</section>
<footer class="pt-4 section fotClc">
   <div class="container">
      <div class="row row-30 justify-content-between">
         <div class="col">
            <div class="">
               <div class="d-md-flex align-items-center">
                  <a class="brand" routerLink="/"><img src="{{tUrl2}}/logo.png"></a>
                  <span class="by_txt ml-5">by</span>
                  <a class="ml-md-4" href="http://igmcorp.net/">
                  <img class="mt-2 mt-md-0" src="{{tUrl2}}/igm-logo.png" width="250"></a>
               </div>
               <!-- Rights-->
               <p class="mt-3 mb-1">A perfect blend of polymeric technology combined with the beauty and durability of Natural Quartz.</p>
               <div class="col-md-12 col-xl-12 px-0">
                  <div>
                     <ul class="nav-list d-flex">
                        <li><a routerLink="/" class="text-primary">Home</a></li>
                        <li><a class="text-primary" routerLink="/company">About Us</a></li>
                        <li><a class="text-primary" routerLink="/warranty-registration">Coalesce Quartz Warranty</a></li>
                        <li><a class="text-primary"  routerLink="/contact">Contact Us</a></li>
                        <li><a class="text-primary" routerLink="/privacypolicy">Privacy Policy</a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <!-- <h5>Contact Us</h5> -->
            <div class="row">
               <div class="mb-3">
                  <div class="row">
                     <div class="col pl-0">
                        <div class="col mb-3">
                           <ul class="sNet nav">
                              <li><a class="waves-effect waves-dark" href="https://www.facebook.com/CoalesceQuartz/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                              <li><a class="waves-effect waves-dark" href="https://www.pinterest.com/CoalesceQuartz" target="_blank"><i class="fa fa-pinterest-square"></i></a></li>
                              <li><a class="waves-effect waves-dark"  href="https://www.instagram.com/coalescequartz" target="_blank"><i class="fa fa-instagram"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <p class="footaddress mb-0 mt-3">Home Office: <br> {{address}}</p>

               </div>
            </div>
         </div>
      </div>
      <div class="row justify-content-center">
         <p class="mb-1"><span>©&nbsp; </span><span class="copyright-year">{{year}}</span><span>&nbsp;</span><span>COALESCE</span><span>.&nbsp;</span><span>All Rights Reserved.</span></p>
      </div>
   </div>
</footer>