import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SharedService,SeoServiceService } from '../service/index'
import { Lightbox } from 'ngx-lightbox';


import { OwlOptions } from 'ngx-owl-carousel-o';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  tUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  projects ;
  selectedProject
  selectedTab = 'residential';
  selectedLink = 'all'
  _albums = [];
  _albums2 = [];
  residential = [
    {url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/1.jpg' , type: 'kitchen'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/2.jpg' , type: 'kitchen'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/3.jpg' , type: 'kitchen'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/4.jpg' , type: 'kitchen'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/5.jpg' , type: 'kitchen'},

{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/7.jpg' , type: 'bathroom'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/8.jpg' , type: 'bathroom'},

{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/9.jpg' , type: 'livingSpace'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/10.jpg' , type: 'livingSpace'},

{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/11.jpg' , type: 'bedroom'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/12.jpg' , type: 'bedroom'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/13.jpg' , type: 'landry'}



  ]
  commercial = [
    
{url :'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/14.jpg' , type: 'WaitingArea'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/15.jpg' , type: 'WaitingArea'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/16.jpg' , type: 'Reception'},
{url :'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/17.jpg' , type: 'Reception'},
{url : 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/gallery/18.jpg' , type: 'Lobby'}
  ];
  projectListdata= [
    {   
   Company:  'EGM - Paterson NJ',
   Quantity:  '100 slabs',
   Material:  '3cm Pebble Beach Quartz',
   Project:  'Hotel Kitchens and Bathrooms NJ',
   desc: `One of our clients in New Jersey was looking for a durable and beautiful material to revamp their hotel kitchens and bathrooms. And as kitchen and bathroom spaces have high footfall, a quartz slab serves as the ideal choice due to its non-slippery nature, scratch & stain resistance, and easy maintenance properties. So, our expert team suggested various products from our broad range of quartz collection, and the product that stood out for them was the wonderful white-colored Pebble Beach quartz. Keeping up with the style statement of the hotel, this quartz added a whole new dimension to its overall aesthetics.`
    },
   {
   Company:  'Tile Bar',
   Quantity:  'Approximately 7,200/sf mixed of all 3 materials',
   Fabricator:  'Faithful Countertop - Paterson NJ',
   Material:  '5cm Steel Grey Flamed/ 5cm Black Absolute Flamed/ 5cm Black Pearl Flamed',
   Project: ' Outside of Building Staten Island NY',
   desc:`For a project located outside of Building Staten Island in New York, our team of professionals analyzed its
    specific needs and chose to go ahead with a combination of three dark-hued materials, namely Steel Grey,
     Black Absolute, and Black Pearl. As these materials were intended for the exterior setting,
      a flamed finish was applied to each of these materials as slip-resistance is extremely crucial in outdoor applications.
    They have not only enhanced the value of the building’s outdoor space but also its durability.`
   },
   
   {Company:  'EMM - Newark NJ',
   Quantity:  '35 slabs',
   Material:  '3cm White Zen quartz 135x77 Jumbo',
   Project:  'Apartment Building NJ',
   desc:`We, at CoalesceQuartz, come with several years of experience and expertise in designing and remodeling residential projects.
    To cater to the customized requirements for an apartment building project in New Jersey, we showed the client a wide range of quartz products available at our showroom. Considering the exquisite beauty, pristine white color, and durable properties of quartz, they chose the White Zen quartz slab for multiple indoor/outdoor applications for their apartment building. `
  },
   
  //  {Company:  'Elite Kitchen & Bath - Clifton NJ',
  //  Material:  '3cm White Lace Quartz',
  //  Quantity:  '10 slabs' ,
  //  Project:  'Apartment Building NJ'},
   
   {Company:  'Allstiles - Monroe NY',
   Quantity:  '30 slabs',
   Material:  '3cm Calacatta Oro Quartz',
   Project: 'Apartment Buildings NY',
   desc:`For one of our apartment buildings project in New York, we walked the client through an exclusive range of quartz products available at our NY-based showroom. Based on their custom requirements,
    they picked the Calacatta Oro quartz slab as it perfectly suited their unique design scheme. Being a phenomenal stone choice, its characteristic white backdrop featuring veining patterns helped add a sense of depth and style to these apartment buildings. `
  },
  //  {
  //  Company:  'Barraco Marble Granite - Chestnut Ridge NY',
  //  Material:  '3cm Steel Grey DF + 3cm White Lace',
  //  Quantity:  '15 slabs Steel Grey', //. 10 slabs White Lace,
  //  Project: 'Apartment Building NY'}

   ];
  // projectListdata= [
  //   {   
  //  Company:  'EGM - Paterson NJ',
  //  Material:  '3cm Pebble Beach Quartz',
  //  Quantity:  '100 slabs',
  //  Project:  'Hotel Kitchens and Bathrooms NJ'
  //   },
  //  {
  //  Company:  'Tile Bar',
  //  Material:  '5cm Steel Grey Flamed/ 5cm Black Absolute Flamed/ 5cm Black Pearl Flamed',
  //  Quantity:  'Approximately 7,200/sf mixed of all 3 materials',
  //  Fabricator:  'Faithful Countertop - Paterson NJ',
  //  Project: ' Outside of Building Staten Island NY'
  //  },
   
  //  {Company:  'EMM - Newark NJ',
  //  Material:  '3cm White Zen quartz 135x77 Jumbo',
  //  Quantity:  '35 slabs',
  //  Project:  'Apartment Building NJ'},
   
  // //  {Company:  'Elite Kitchen & Bath - Clifton NJ',
  // //  Material:  '3cm White Lace Quartz',
  // //  Quantity:  '10 slabs' ,
  // //  Project:  'Apartment Building NJ'},
   
  //  {Company:  'Allstiles - Monroe NY',
  //  Material:  '3cm Calacatta Oro Quartz',
  //  Quantity:  '30 slabs',
  //  Project: ' Apartment Buildings NY'},
  // //  {
  // //  Company:  'Barraco Marble Granite - Chestnut Ridge NY',
  // //  Material:  '3cm Steel Grey DF + 3cm White Lace',
  // //  Quantity:  '15 slabs Steel Grey', //. 10 slabs White Lace,
  // //  Project: ' Apartment Building NY'}

  //  ];

  customeOption: OwlOptions = {
    loop: true,
    margin: 10,
    dots: false,
    //animateOut: 'flipOutX',
    animateIn: 'fadeInRight',
    smartSpeed: 450,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    nav: false,
    responsive: {
      0: {
        items: 1,
        margin: 0,
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };
  customeOption2: OwlOptions = {
    loop: true,
    margin: 20,
    //default settings:
    autoplay: true,
    nav: true,
    autoplayTimeout: 3500,
    autoplayHoverPause: false,
    // responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      450: {
        items: 2,
        margin: 10
      },
      768: {
        items: 3
      },
      1000: {
        items: 5
      },
      1200: {
        items: 6
      }
    }
  };
  constructor(private api: ApiService, private shared: SharedService,private _seoService: SeoServiceService,
    private activatedRoute: ActivatedRoute,private router: Router, private _lightbox : Lightbox) { 
      this.activatedRoute.fragment.subscribe( fragment =>{
        let items = ['residential', 'commercial'];
        let x  = items.findIndex(f => f == fragment);
        if(x != -1) {
          setTimeout(() => {
            this.selectedTab =  fragment
            document.getElementById(fragment).click();
          }, 500);
        }
      })
      // this.getCategory();
      this.getProjects();
      // this.shared.cleardata();
      for (let i = 0; i < this.residential.length; i++) {
        const src = this.residential[i].url;
        const caption = '';
        const thumb =this.residential[i].url;
        const album = {
           src: src,
           caption: caption,
           thumb: thumb
        };
   
        this._albums.push(album);
      }
      this.getProjects();
      // this.shared.cleardata();
      for (let i = 0; i < this.commercial.length; i++) {
        const src = this.commercial[i].url;
        const caption = '';
        const thumb =this.commercial[i].url;
        const album = {
           src: src,
           caption: caption,
           thumb: thumb
        };
   
        this._albums2.push(album);
      }

      this._seoService.updateTitle('Premium Quality Quartz Products for Different Projects | Coalesce Quartz');
      this._seoService.updateDescription('Are you planning to renovate your applications with quartz products? Explore our wide range of quartz slabs and modify your space according to requirements.')

  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() {  

      // $('.slider4').owlCarousel();
      
      // $('.product-carousel').owlCarousel();
      
     
      $('.filters ul li').click(function(){
        $('.filters ul li').removeClass('active');
        $(this).addClass('active');
        
        var data = $(this).attr('data-filter');
     
      });
      
 
  
    })

    $(document).ready(function(){

      $(".filter-button").click(function(){
        $(this).addClass('active');
         var value = $(this).attr('data-filter');
         
         if(value == "all")
         {
             //$('.filter').removeClass('hidden');
             $('.filter').show('1000');
            
         }
         else
         {
 //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
 //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
             $(".filter").not('.'+value).hide('3000');
             $('.filter').filter('.'+value).show('3000');
         
             
         }
     });
      
      $('.filter-button').removeClass('active');
  
      
  
  
      $(".filter-button2").click(function(){
         $(this).addClass('active');
          var value = $(this).attr('data-filter');
          
          if(value == "all")
          {
              $('.filter2').show('1000');
             
          }
          else
          {

              $(".filter2").not('.'+value).hide('3000');
              $('.filter2').filter('.'+value).show('3000');
          
              
          }
      });
      
      $('.filter-button2').removeClass('active');
  
  });
    // $( '.modal-backdrop fade show').on('click', function(event) {
    //   $(".ekko-lightbox modal fade in show").each(function(){

    //     $(this).hide();
        
    //   });
    // });
    
  }

  getProjects() {
    this.api.fetchData('projects',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.projects = res['data']
        }
      }
    )
  }

  open(index: number): void {
    // open lightbox
    // console.log(index, this._albums.length, this._albums2.length, this.selectedTab)
    if(this.selectedTab ==  'residential') {
      this._lightbox.open(this._albums, index)
    } else {
      console.log(index, this._albums.length, this._albums2.length)
      this._lightbox.open(this._albums2, index)
    }
    // this.selectedTab ==  'residential' ?   : this._lightbox.open(this._albums2, index)

  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  changeData(id) {
    document.getElementById(id).click()
    this.selectedLink = 'all'
  }
}
