<main>
    <section class="py-5 bg-light locBdyS">
        <section class="heading-title-bg-main our-company-title">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="heading-title-inner text-center">
                            <a href="javascript:void(0)" class="btn btn-default"> Coalesce Quartz Warranty Registration </a>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        
            <section class="white-bg ourcompany">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="heading-blue blue-black">Coalesce Quartz Warranty</h1>
                            <p class="headin-para-description text-center">To get the easy access to our residential installation process,<br>download the warranty guidelines document from the link given below.</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6 col-sm-12 col-md-push-3 col-sm-push-0">
                            <div class="install-wrapper-doc text-center">
                               
                                <p>
                                    <a href="https://slabs.igmsurfaces.com/assets/Warranty%20Cosmos%20Quartz_3.pdf" target="_blank" class="btn btn-default" role="button"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> VIEW WARRANTY</a>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </section>
                <section class="grey_bg">
                    <div class="container">
                        <form [formGroup]="waarentyForm" 
                        (ngSubmit)="submit(waarentyForm.value)" >    
                            <div class="col-md-12">
                                <h1 class="heading-blue blue-black">WARRANTY REGISTRATION FORM</h1>
                                <p class="headin-para-description text-center">Complete the Coalesce Quartz Warranty registration to reassure yourself about the quality and durability we offer. It also makes sure that we have your details on record for any requests regarding potential product care or warranty assistance. </p>
                                <p class="headin-para-description text-center">Getting your product registered is easy. Start NOW!</p>
                            </div>
                            <div class="col-md-12">
                               
                                <div class="warranty_registration">
                                    <div class="col-md-12">
                                        <h1 class="heading-grey blue-black text-left">Personal Information</h1>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">First Name<span class="star_red">*</span></label> 
                                                <input type="text" name="firstname" class="form-control" id="firstname" 
                                                formControlName="firstname" 
                                                 [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" >
                                                 <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                                    
                                                    <div style="color:#D80003;" *ngIf="f.firstname.errors.required">* This field is required.</div>
                                                </div>
                                                </div>
                                            
                                           
                                            <br>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Last Name<span class="star_red">*</span></label>
                                                <input type="text"  name="lastname" class="form-control " id="lastname" formControlName="lastname"   [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" required="required">
                                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.lastname.errors.required">* This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Phone Number<span class="star_red">*</span></label>
                                                <input type="text"  name="phoneNumber" class="form-control " id="phoneNumber" 
                                                formControlName="phoneNumber"  [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" 
                                                required="required">
                                                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.phoneNumber.errors.required">* This field is required.</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Apartment Number</label>
                                                <input type="text"  name="aptno" class="form-control " id="AptNo" formControlName="aptno">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Street<span class="star_red">*</span></label>
                                                <input type="text"  name="street" class="form-control" formControlName="street" [ngClass]="{ 'is-invalid': submitted && f.street.errors }" required="required">
                                                <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.street.errors.required">* This field is required.</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">City<span class="star_red">*</span></label>
                                                <input type="text"  name="city" class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"  required="required">
                                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.city.errors.required">* This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">ZipCode<span class="star_red">*</span></label>
                                                <input type="text"  name="zipcode" class="form-control" formControlName="zipcode" [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }" required="required">
                                                <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.zipcode.errors.required  ">* This field is required.</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="country">Country/State<span class="star_red">*</span></label>
                                                <select id="country" name="country" class="chosen-select form-control" formControlName="country" 
                                                [ngClass]="{ 'is-invalid': submitted && f.country.errors }" required="required" >
                                                    <option value="country">Select Country/State</option>                        
                                                    <optgroup label="USA">
                                                        <option>Alabama</option>
                                                        <option>Alaska</option>
                                                        <option>Arizona</option>
                                                        <option>Arkansas</option>
                                                        <option>California</option>
                                                        <option>Colorado</option>
                                                        <option>Connecticut</option>
                                                        <option>Delaware</option>
                                                        <option>Florida</option>
                                                        <option>Georgia</option>
                                                        <option>Hawaii</option>
                                                        <option>Idaho</option>
                                                        <option>Illinois</option>
                                                        <option>Indiana</option>
                                                        <option>Iowa</option>
                                                        <option>Kansas</option>
                                                        <option>Kentucky</option>
                                                        <option>Louisiana</option>
                                                        <option>Maine</option>
                                                        <option>Maryland</option>
                                                        <option>Massachusetts</option>
                                                        <option>Michigan</option>
                                                        <option>Minnesota</option>
                                                        <option>Mississippi</option>
                                                        <option>Missouri</option>
                                                        <option>Montana</option>
                                                        <option>Nebraska</option>
                                                        <option>Nevada</option>
                                                        <option>New Hampshire</option>
                                                        <option>New Jersey</option>
                                                        <option>New Mexico</option>
                                                        <option>New York</option>
                                                        <option>North Carolina</option>
                                                        <option>North Dakota</option>
                                                        <option>Ohio</option>
                                                        <option>Oklahoma</option>
                                                        <option>Oregon</option>
                                                        <option>Pennsylvania</option>
                                                        <option>Rhode Island</option>
                                                        <option>South Carolina</option>
                                                        <option>South Dakota</option>
                                                        <option>Tennessee</option>
                                                        <option>Texas</option>
                                                        <option>Utah</option>
                                                        <option>Vermont</option>
                                                        <option>Virginia</option>
                                                        <option>Washingtonv</option>
                                                        <option>West Virginia</option>
                                                        <option>Wisconsin</option>
                                                        <option>Wyoming</option>
                                                    </optgroup>
                                                   

                                                </select>
                                                <!-- <div class="chosen-container chosen-container-single" style="width: 363px;" title="" id="country_chosen"><a class="chosen-single"><span>Select Country/State</span><div><b></b></div></a><div class="chosen-drop"><div class="chosen-search"><input type="text" autocomplete="off"></div><ul class="chosen-results"></ul></div></div>
                                                <span style="display:none;" for="country" generated="true" id="country_error" class="error">Please select country</span> -->
                                                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.country.errors.required  ">* This field is required.</div>
                                                </div>
                                            </div>
                                            
                                        </div>            			
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Subdivision</label>
                                                <input type="text"  name="subdivision" class="form-control"  formControlName="subdivision">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">            			
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Email<span class="star_red">*</span></label>
                                                <input type="email"   name="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required="required">
                                            </div>
                                            <div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.email.errors.required    ">* This field is required.</div>
                                                </div>
                                                <!-- <small style="color: red;" *ngIf="waarentyForm.get('email').invalid && waarentyForm.get('email').touched  "> * Email This field is required.</small>
                                                <small  style="color: red;" *ngIf="waarentyForm.get('email').errors?.email">*Email address is invalid</small> -->
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                                <div class="warranty_registration">
                                    <div class="col-md-12">
                                        <h1 class="heading-grey blue-black">Product Information</h1>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Location<span class="star_red">*</span></label>
                                                <select name="location" class="chosen-select form-control" required="required" 
                                                formControlName="location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }"
                                                >
                                                <option  value="" class="text-dark">Please select location</option>
                                                <option *ngFor="let item of location" value="{{item.city_slug}}" class="text-dark">{{item.address}}</option>
                                                                                    
                                                </select>
                                                <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.location.errors.required    ">* This field is required.</div>
                                                </div>
                                            </div>

                                        </div>                        
                                    </div>
                                    
                                    <div class="row"  *ngFor="let item of waarentyForm.get('product').controls; let i = index;"
                                    [formGroup]="item">     
                                                       
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>SERIAL/Invoice Number<span class="star_red">*</span></label>
                                                <input class="form-control"  name="serial_number"
                                                formControlName="serial_number" 
                                                 required="required">
                                       
                                                <!-- <div *ngIf="submitted && f.serial_number.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.serial_number.errors.required">* This field is required.</div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-md-10"> 
                                                    <div class="form-group">
                                                        <label>COLOR<span class="star_red">*</span></label>
                                                        <input class="form-control"  name="color" formControlName="color" 
                                                         required="required">
                                               
                                                        <!-- <div *ngIf="submitted && f.color.errors" class="invalid-feedback">
                                                            <div style="color:#D80003;" *ngIf="f.color.errors.required">* This field is required.</div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                
                                            <div class="col-md-2" >

                                                <a style="cursor: pointer;" *ngIf="i == 0 " class="btn btn-defult btn-plus add_field_button" (click)="addItem()"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                                <a style="cursor: pointer;"  *ngIf="i > 0 " class="btn btn-defult btn-plus remove_field"  (click)="remove(i)"><i class="fa fa-close" aria-hidden="true"></i></a>
                                            </div>
                                            </div>
                                            
                                        </div>
                                              
                                    </div>
                                    <div class="input_fields_wrap"></div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Installation Date<span class="star_red">*</span></label>
                                                <div class="input-group date" data-provide="datepicker">
                                                                <input name="installation_date" type="date" id="installation_date" class="form-control" formControlName="installation_date" [ngClass]="{ 'is-invalid': submitted && f.installation_date.errors }" required="" >
                                                                <!-- <a class="input-group-addon" style="cursor: pointer;"><i class="fa fa-calendar"></i></a> -->
                                                            
                                                                <div *ngIf="submitted && f.installation_date.errors" class="invalid-feedback">
                                                                    <div style="color:#D80003;" *ngIf="f.installation_date.errors.required    ">* This field is required.</div>
                                                                </div>
                                                            </div>
                                                            
                                                
                                                        
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Total Square Footage Installed<span class="star_red">*</span></label>
                                                <input class="form-control"  name="total_square_footage" 
                                                formControlName="total_square_footage" [ngClass]="{ 'is-invalid': submitted && f.total_square_footage.errors }" required="required">
                                       
                                                <div *ngIf="submitted && f.total_square_footage.errors" class="invalid-feedback">
                                                    <div style="color:#D80003;" *ngIf="f.total_square_footage.errors.required">* This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                    
                                    </div>
                                    <div class="row">
                    
                    
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Application</label>
                                                <div class="radio">
                                                    <label><input type="radio" class="radio-btn" value="Residential" name="application"
                                                        formControlName="application">
                                                    Residential</label>
                                                </div>
                                                <div class="radio">
                                                    <label><input type="radio" class="radio-btn" value="Commercial" name="application"
                                                        formControlName="application">
                                                    Commercial</label>
                                                </div>
                                            </div>
                                        </div>   
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Installation Type</label>
                                                <div class="radio">
                                                    <label><input type="radio" class="radio-btn" value="Re-Model" name="installation_type"
                                                        formControlName="installation_type">
                                                    Re-Model</label>
                                                </div>
                                                <div class="radio">
                                                    <label><input type="radio" class="radio-btn" value="New Construction"
                                                        formControlName="installation_type" name="installation_type">
                                                    New Construction</label>
                                                </div>
                                                <div class="radio">
                                                    <label><input type="radio" class="radio-btn" formControlName="installation_type"
                                                        value="Other" name="installation_type">
                                                    Other</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>	
                                <div class="warranty_registration">
                                    <div class="col-md-12">
                                        <h1 class="heading-grey blue-black">Fabricator/Installer Information</h1>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <label>Company Name<span class="star_red">*</span></label>
                                            <input name="company_name" type="text" class="form-control" formControlName="company_name"  [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" required/>
                                            <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                                <div style="color:#D80003;" *ngIf="f.company_name.errors.required    ">* This field is required.</div>
                                            </div>
                                        </div>
                                     

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Street</label>
                                                <input type="text"  name="f_street" class="form-control"
                                                formControlName="f_street">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">City</label>
                                                <input type="text"  name="f_city" class="form-control"
                                                formControlName="f_city">
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">ZipCode</label>
                                                <input type="text"  name="f_zipcode" class="form-control"
                                                formControlName="f_zipcode">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">State</label>
                                                <input type="text"  name="f_state" class="form-control"
                                                formControlName="f_state">
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="Name">Country</label>
                                                <input type="text"  name="f_country" class="form-control"
                                                formControlName="f_country">
                                            </div>
                                        </div>
                                    </div>
                                       <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <!-- <div class="g-recaptcha" data-sitekey="6Le2QlwUAAAAALHL1_l9Esngbj8T4LFmvMh5qvYK"><div style="width: 304px; height: 78px;"><div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Le2QlwUAAAAALHL1_l9Esngbj8T4LFmvMh5qvYK&amp;co=aHR0cHM6Ly9zbGFicy5pZ21zdXJmYWNlcy5jb206NDQz&amp;hl=en&amp;v=TDBxTlSsKAUm3tSIa0fwIqNu&amp;size=normal&amp;cb=q9u5pdwvc717" width="304" height="78" role="presentation" name="a-etaf50etf8cy" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea></div><iframe style="display: none;"></iframe></div> -->
                                                <ngx-recaptcha2 #captchaElem
                                                [siteKey]="siteKey"
                                                (success)="handleSuccess($event)"
                                                [useGlobalDomain]="false"
                                                [size]="size"
                                                [hl]="lang"
                                                [theme]="theme"
                                                [type]="type"
                                                formControlName="captcha">
                                            </ngx-recaptcha2>

                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                           
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                                    <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                                       <div class="d-flex align-items-center">
                                          <img src="{{tinyUrl}}/loader.svg" class="img-fluid" width="50" /> <p class="mb-0 mt-1 mr-3">Loading...</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                                    <div class=" py-2 px-5 text-center">
                                       
                                       <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                                    </div>
                                 </div>
                                <div class="row">
                                    <div class="col-md-12 text-center submit-btn">
                                        <input type="hidden" name="action" value="1"> 
                                        <div class="col-lg-12" align="center"><div style="display:none;" class="loader"></div></div><button type="submit" class="btn btn-default btn-lg warranty-registration" >Submit</button>
                                    </div>
                                </div>
                                
                                <br><br>
                                
                            </div>
                        </form> 
                    </div>
                    </section> 
                   </section>
</main>