<main>
   <section class="py-5 bg-light secPad2">
      <div class="showcase animated">
         <div class="section-inner">
            <div class="wrapper ">
               <div class="container">
                  <div class="row inner large-title text-center">
                     <div class="col-12">
                        <div class="large-title text-center">
                           <h2 class="mb-3">Privacy Policy</h2>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <a id="showHere"></a>
      <div class="section1 animated">
         <div class="section-inner">
            <div class="wrapper">
               <div class="container">
                  <div class="row inner">
                     <div class="col-sm-12">
                        <div class="content-center">
                           <article class="content">
                              <p><b>PRIVACY POLICY</b></p>
                              <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>
                              <p><b>What personal information do we collect from the people that visit our blog, website or app?</b></p>
                              <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, phone number or other details to help you with your experience.</p>
                              <p><b>When do we collect information?</b></p>
                              <p>We collect information from you when you fill out a form or enter information on our site.</p>
                              <p><b>How do we use your information?</b></p>
                              <p>We may use the information we collect from you when you register, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
                              <ul>
                                 <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                                 <li>To improve our website in order to better serve you.</li>
                                 <li>To allow us to better service you in responding to your customer service requests.</li>
                                 <li>To send periodic emails regarding your order or other products and services.</li>
                                 <li>To follow up with them after correspondence (email or phone inquiries)</li>
                              </ul>
                              <p><b>Do we use 'cookies'?</b></p>
                              <p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items you preferred when you last visited this website. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
                              <p><b>We use cookies to:</b></p>
                              <p>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</p>
                              <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.</p>
                              <p>If you turn cookies off, Some of the features that make your site experience more efficient may not function properly. It won't affect the user's experience that make your site experience more efficient and may not function properly.</p>
                              <p><b>Third-party disclosure</b></p>
                              <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</p>
                              <p>However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                              <p><b>Third-party links</b></p>
                              <p>We do not include or offer third-party products or services on our website.</p>
                              <ul>
                                 <li>COPPA (Children Online Privacy Protection Act)</li>
                                 <li>When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control.</li>
                                 <li>The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.</li>
                                 <li>We do not specifically market to children under the age of 13 years old.</li>
                              </ul>
                              <p><b>CAN SPAM Act</b></p>
                              <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>
                              <p><b>We collect your email address in order to:</b></p>
                              <p>To be in accordance with CANSPAM, we agree to the following:</p>
                              <p>If at any time you would like to unsubscribe from receiving future emails, you can email us at and we will promptly remove you from ALL correspondence.</p>
                              <p><b>Contacting Us</b></p>
                              <p>If you have any questions regarding this privacy policy, you may contact us using the information below.</p>
                              <p>Coalesce Quartz</p>
                              <p>Mailing address:</p>
                              <p>2038 83rd Street, North Bergen, NJ 07047</p>
                              <p>Phone Number: 201-869-5200</p>
                              <p>E-mail: <a href="mailto:cq@coalescequartz.com">cq@coalescequartz.com</a></p>
                           </article>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>