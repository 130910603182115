import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { CompanyComponent } from './company/company.component';
import { ContactComponent } from './contact/contact.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { HomeComponent } from './home/home.component';
import { LocationComponent } from './location/location.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { ProductpageComponent } from './productpage/productpage.component';
import { ProjectComponent } from './project/project.component';
import { QuartzlistingComponent } from './quartzlisting/quartzlisting.component';
import { WarrantyregistrationComponent } from './warrantyregistration/warrantyregistration.component';

const routes: Routes = [{ path: '',  component: HomeComponent },
{ path: '', component: HomeComponent },
{ path: 'products', component: ProductpageComponent },
{ path: 'customerService', component: CustomerServiceComponent },
{ path: 'contact', component: ContactComponent },
{ path: 'where-to-buy', component: LocationComponent },
{ path: 'projects', component: ProjectComponent },
{ path: 'company', component: CompanyComponent },
{ path: 'privacypolicy', component: PrivacyPolicyComponent },
{ path: 'quartz', component: QuartzlistingComponent },
{ path: 'quartz/:type', component: ProductdetailComponent},
{ path: 'book-appointment', component: BookAppointmentComponent},
{ path: 'warranty-registration', component: WarrantyregistrationComponent},
{ path: 'error-404-not-found', component: PagenotfoundComponent, data: {
  title: 'IGM Natural Stone and Quartz',
  // description:'Description Meta Tag Content',
  // ogUrl: 'your og url'
} },
{ path: '**', redirectTo:"error-404-not-found", pathMatch:"full"},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 30],
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
