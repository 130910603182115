import { Injectable, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  showFilter
  currenturl
  searchedKey;
  resetFilter;
  @Input() changeFilter;
  constructor() { }
}
