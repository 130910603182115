<main>
    <section class="tringle AraAbt secPad2 bg-light">
       <div class="container py-md-5 py-3">
          <div class="row align-items-center">
             <div class="col-lg-6 col-md-12">
                <div class="capAbt">
                   <!-- Section Tittle -->
                   <div class="large-title text-left">
                      <h2 class="mb-3">Projects</h2>
                   </div>
                   <p class="text-left mb-1">
                     Be it a commercial or residential project: quartz products look amazing in any space. Whether it is a small bathroom or a huge kitchen, quartz looks elegant and makes the space look fascinating. Do browse through our wide range of products and install it anywhere you want in your living space. For residential projects, we offer quartz stones that are durable, flexible, most importantly, lasts forever.</p>
                </div>
             </div>
             <div class="col-lg-6 col-md-12">
               <owl-carousel-o [options]="customeOption">
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/5.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/7.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/9.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/12.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/14.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>
                  <ng-template carouselSlide>
                     <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                        <span class="show-off">
                          <img src="{{tUrl}}/gallery/17.jpg" class="rounded m-auto w-auto img-fluid"/>
                        </span>
                     </div>
                  </ng-template>                    
              </owl-carousel-o>
             </div>
          </div>
       </div>
    </section>
    <section class="contact sBG iArea2 text-white">
       <div class="container-fluid">
          <div class="row justify-content-center">
             <div class="col-lg-12 p-md-5 p-3 text-center">
                <p class="text-center mt-3 mb-0"><button class="btn btn-theme mb-2 border-0" [routerLink]="['../book-appointment']" tabindex="0"> Click here to Schedule an appointment.<i  aria-hidden="true" class="ml-2 fa fa-long-arrow-right"></i></button></p>
             </div>
          </div>
       </div>
    </section>
    <section class="py-5" id="projectList">
       <div class="container">
          <div class="row mb-3 align-items-start justify-content-center">
             <div class="col-xl-4 col-lg-4 text-center">
                <div class="large-title text-center">
                   <h2 class="mb-3">Projects</h2>
                </div>
                <ul class="list-group mt-lg-5 pt-lg-2 mb-4">
                   <li class="list-group-item p-0 bg-light" *ngFor="let item of projectListdata">
                      <a class="text-left nav-link" data-toggle="modal" data-target="#exampleModalLong-project" (click)="selectedProject = item" href="javascript:void(0)">{{item.Project}}</a>
                    </li>
                </ul>
             </div>
             <div class="col-xl-8 col-lg-8 text-center">
                <ul class="justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist">
                   <li class="nav-item">
                      <a class="nav-link active h4" id="residential" data-toggle="pill" href="#pills-home" role="tab" (click)="this.selectedTab =  'residential';changeData('all-rasidential')" aria-controls="pills-home" aria-selected="true">RESIDENTIAL</a>
                   </li>
                   <li class="nav-item">
                      <a class="nav-link h4" id="commercial" data-toggle="pill" href="#pills-profile" role="tab" (click)="this.selectedTab =  'commerical';changeData('all-commercial')" aria-controls="pills-profile" aria-selected="false">COMMERCIAL</a>
                   </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                   <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="residential">
                      <div class="row">
                         <div class="col-lg-12 text-center">
                            <button class="btn btn-default filter-button" data-filter="all" id="all-rasidential" (click)="selectedLink = 'all'" [ngClass]="{'btn-primary': selectedLink == 'all'}">All</button>
                            <button class="btn btn-default filter-button" data-filter="kitchen" id="kitchen-rasidential" (click)="selectedLink = 'kitchen'" [ngClass]="{'btn-primary': selectedLink == 'kitchen'}">Kitchen</button>
                            <button class="btn btn-default filter-button" data-filter="bathroom" id="bathroom-rasidential"(click)="selectedLink = 'bathroom'" [ngClass]="{'btn-primary': selectedLink == 'bathroom'}">Bathroom</button>
                            <button class="btn btn-default filter-button" data-filter="livingSpace" id="livingSpace-rasidential" (click)="selectedLink = 'livingSpace'" [ngClass]="{'btn-primary': selectedLink == 'livingSpace'}">Living Space</button>
                            <button class="btn btn-default filter-button" data-filter="bedroom" id="bedroom-rasidential" (click)="selectedLink = 'bedroom'" [ngClass]="{'btn-primary': selectedLink == 'bedroom'}">Bedroom</button>
                            <button class="btn btn-default filter-button" data-filter="landry" id="landry-rasidential" (click)="selectedLink = 'landry'" [ngClass]="{'btn-primary': selectedLink == 'landry'}">Laundry Room</button>
                         </div>
                         <br>
                      </div>
                      <div class="row justify-content-center align-items-center mt-3" *ngIf="residential">
                         <div *ngFor="let item of residential let i = index;" data-toggle="lightbox" data-gallery="gallery" href="{{tUrl}}/gallery/1.jpg" 
                         class="col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2"  [ngClass]="item.type"> 
                            <img src="{{item.url}}" (click)="open(i)" class="img-fluid"/>
                         </div>
                      </div>
                   </div>
                   <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="commercial">
                      <div class="row">
                         <div class="col-lg-12 text-center">
                            <button class="btn btn-default filter-button2" data-filter="all" id="all-commercial" (click)="selectedLink = 'all'" [ngClass]="{'btn-primary': selectedLink == 'all'}">All</button>
                            <button class="btn btn-default filter-button2" data-filter="WaitingArea" id="WaitingArea-commercial" (click)="selectedLink = 'waitingArea'" [ngClass]="{'btn-primary': selectedLink == 'waitingArea'}">Waiting Area</button>
                            <button class="btn btn-default  filter-button2" data-filter="Reception" id="Reception-commercial"(click)="selectedLink = 'reception'" [ngClass]="{'btn-primary': selectedLink == 'reception'}">Reception</button>
                            <button class="btn btn-default filter-button2" data-filter="Lobby" id="Lobby-commercial" (click)="selectedLink = 'lobby'" [ngClass]="{'btn-primary': selectedLink == 'lobby'}">Lobby</button>
                         </div>
                         <br>
                      </div>
                      <div class="row justify-content-center align-items-center mt-3">
                         <div *ngFor="let item of commercial let i = index;" data-toggle="lightbox" data-gallery="gallery" href="{{tUrl}}/gallery/14.jpg"
                          class="col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2" [ngClass]="item.type">
                            <img src="{{item.url}}" (click)="open(i)" class="img-fluid"/>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>
 <div class="modal fade" id="exampleModalLong-project" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
       <div class="modal-content">
          <div class="modal-header" *ngIf="selectedProject">
             <h5 class="modal-title" id="exampleModalLongTitle"> {{selectedProject.Project}}</h5>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
             </button>
          </div>
          <div class="modal-body m-2 py-2 px-4 model-bg-color" *ngIf="selectedProject">
            <div class="large-title text-center">
              <p class="my-3 py-3">{{selectedProject.desc}}</p>
             </div>
          </div>
          <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
       </div>
    </div>
 </div>