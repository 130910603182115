import { Component } from '@angular/core';
import { SharedService } from  './service/index'
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'COALESCE';
  constructor(private shared: SharedService) {
   }
  ngAfterViewInit() {
     
    
    
    
    (function ($) {
      $(function () {
        $(document).off('click.bs.tab.data-api', '[data-hover="tab"]');
        $(document).on('mouseenter.bs.tab.data-api', '[data-toggle="tab"], [data-hover="tab"]', function () {
          $(this).tab('show');
        });
      });
    })(jQuery);
  }

  changeOfRoutes() {
    this.shared.currenturl = window.location.pathname;
    window.onscroll = function() {myFunction()};
    $(document).ready(function(){
      let pathname = window.location.pathname;
      var header = document.getElementById("coalsbody");
      // console.log(pathname.indexOf("/quartz/"));
      if(pathname.indexOf("/quartz/") != -1) {
        // console.log('runnn')
        header.classList.add("noSticky");
      } else {
        header.classList.remove("noSticky");

      }
      
     
    });
    var header = document.getElementById("hedDes");
    var sticky =  header ? header.offsetTop :'';
    
    function myFunction() {
        if (window.pageYOffset > sticky) {
          // let x = header.classList.findIndex(f => f == 'sticky' )
          header.classList.add("sticky");
          $('.slider-heading-product').addClass('stick');
          $('.fBtn').removeClass('fSrh');

        } else {
          header.classList.remove("sticky");
          $('.slider-heading-product').removeClass('stick');
           $('.fBtn').addClass('fSrh');
        }
    }

  }


}
