import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {

  timer = 5
  constructor(private router: Router) { 
    this.redirect()
  }

  ngOnInit(): void {
  }
  redirect() {
    // setInterval(() => {
    //   this.timer--;
    // },1000)
  setTimeout(() => {
 this.router.navigate(['']);
    }, 5000);
  }
}
