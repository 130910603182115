import { Component, OnInit } from '@angular/core';
import { SharedService, ApiService, SeoServiceService } from '../service/index'
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor( private shared: SharedService, private api: ApiService, private _seoService: SeoServiceService,) { 
    // this.shared.cleardata();
    // this.getCategory();
    this._seoService.updateTitle('Privacy Policy | Coalesce Quartz');
    this._seoService.updateDescription('This privacy policy applies whenever you access our website. It would inform you about how your personal information would be collected and processed.')
  }

  ngOnInit(): void {
  }
  // getCategory() {
  //   this.api.fetchData('product_category',{},'GET').subscribe(
  //     res => {
  //       if(res['status'] == 1) {
  //         this.shared.categoryList = res['category']
  //       }
  //     }
  //   )
  // }

}
